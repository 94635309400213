<template>
  <v-btn @click="exportTableItems()" color="white" prepend-icon="mdi-download">
    <span>{{ exportMessage }}</span>
  </v-btn>
</template>

<script>
import exportExcel from '../helpers/exportExcel';
import { mapGetters } from 'vuex';

export default {
  props: ["tableItems", "tableType", "exportMessage", "workspaces"],
  data() {
    return {
      exportArray: []
    }
  },
  computed: {
    ...mapGetters([
      "islegacy",
      "organizationitems"
    ]),
  },
  methods: {
    exportTableItems() {
      try {
        this.createExportObjectArray()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      } finally {
        exportExcel(this.exportArray, this.tableType)
      }
    },
    createExportObjectArray() {
      this.exportArray = []
      this.exportObject = {}
      if (this.tableType == "qr_codes") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["QRID"] = this.islegacy ? tableRow.qrString : tableRow.qrWorkspaceId
          this.exportObject["Category"] = tableRow.qrCategoryDescription
          this.exportObject["Description"] = tableRow.description
          this.exportObject["Location"] = tableRow.location
          this.exportObject["Url"] = tableRow.url
          this.exportObject["QR url"] = tableRow.qrUrl

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else if (this.tableType == "inventory") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["Description"] = tableRow.description
          this.exportObject["Inventory type"] = tableRow.inventoryType.description
          this.exportObject["Active issues warning"] = tableRow.isIssueWarningActive
          this.exportObject["Selectable on QR codes"] = tableRow.isActive

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else if (this.tableType == "users") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["First name"] = tableRow.firstname
          this.exportObject["Last name"] = tableRow.lastname
          this.exportObject["E-mail"] = tableRow.email
          this.exportObject["Organization"] = tableRow.role === 0 ? this.organizationitems.find(o => o.id === tableRow.organizationId).name : this.organizationitems.filter(o => tableRow.ownerOrganizations.some(oo => oo.id === o.id)).map(o => o.name).join("; ")
          this.exportObject["Workspaces"] = tableRow.role === 0 ? this.workspaces.filter(w => tableRow.workspaceIds.some(wId => wId === w.id)).map(w => w.name).join("; ") : tableRow.role === 1 ? this.workspaces.filter(w => tableRow.ownerOrganizations.some(oo => oo.id === w.organizationId)).map(w => w.name).join("; ") : this.workspaces.filter(w => w.organizationId === tableRow.organizationId).map(w => w.name).join("; ")
          this.exportObject["Administrator"] = tableRow.isAdministrator
          this.exportObject["Owner"] = tableRow.role === 1

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else if (this.tableType == "issues") {
        this.tableItems.forEach(tableRow => {
          this.exportObject["Header"] = tableRow.header
          this.exportObject["Type"] = tableRow.type
          this.exportObject["Alias type"] = tableRow.aliasTypeDescription
          this.exportObject["Created at"] = tableRow.created
          this.exportObject["Status"] = tableRow.status == 0 ? "waiting" : tableRow.status == 1 ? "ongoing" : tableRow.status == 2 ? "closed" : "unknown"
          // eslint-disable-next-line no-undef
          tableRow.picturename !== null && tableRow.imageId !== null ? this.exportObject["Image"] = `${process.env.VUE_APP_APIURL}/shared/bilder/${tableRow.picturename}` : this.exportObject["Image"] = null
          this.exportObject["QrCode"] = tableRow.description
          this.exportObject["Inventory"] = tableRow.inventoryDescription
          this.exportObject["Location"] = tableRow.location
          this.exportObject["QrCategory"] = tableRow.qrCategory
          this.exportObject["Responsible username"] = tableRow.name
          this.exportObject["Responsible email"] = tableRow.email
          this.exportObject["Issuer email"] = tableRow.issuerEmail
          this.exportObject["Issuer message"] = tableRow.message

          this.exportArray.push(this.exportObject)
          this.exportObject = {}
        });
      } else {
        throw new Error("Not a valid table for exporting")
      }
    }
  }
}
</script>
