import HTTP from "../plugins/backendAPI";
import store from "../store";
import router from "../router";

export async function logout() {
  console.log("User is being logged out...");
  if (store.getters.ssoAuthMethod === "SAML") {
    // await HTTP.get("/auth/sign-out/saml")
    //   .then((response) => {
    //     const newWindow = window.open("", "_blank");
    //     newWindow.document.open();
    //     newWindow.document.write(response.data);
    //     newWindow.document.close();
    //   })
    //   .catch(async (e) => {
    //     if (e.response.status === 400) {
    //       await store.dispatch(
    //         "showError",
    //         `Error when logging out user with saml: ${e.response.data}. <br><br>Contact your admin if this issue is not intended.`
    //       );
    //     }
    //     await store.dispatch(
    //       "showError",
    //       `Error when logging out user with saml: Could not logout user with saml <br><br>Contact your admin if this issue persists.`
    //     );
    //   });
  }
  HTTP.defaults.headers.common["Authorization"] = null;
  await store.getters.msalInstance.clearCache();
  await store.dispatch("deleteAuthorization");
  await router.push({ name: "AccountSignIn" });
  await store.dispatch("clearOrganizationitems");
  await store.dispatch("clearWorkspaceitems");
  await store.dispatch("saveWorkspaceId", -1);
  await store.dispatch("saveOrganizationId", -1);
  await store.dispatch("saveSSOAuthMethod", null);
}
