<!-- eslint-disable no-console -->
<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px; gap: 12px;">
      <h2 class="mr-auto">QR Codes</h2>
      <v-btn prepend-icon="mdi-download" @click.stop="exportQRImageDialog = true">Export QR-Code Images</v-btn>
      <ExportButton v-if="isadministrator || issuperadministrator" :table-items="qritems" :table-type="'qr_codes'"
        :export-message="'Export List'"></ExportButton>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col cols=3>
        <v-btn color="white" @click="newqr()"><v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New</v-btn>
      </v-col>
    </v-row>
    <v-data-table dense :search="search" :headers="getQrHeaders" :sort-by.sync="sortOptions.sortBy"
      :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :custom-filter="customFilterForQrCodes"
      :items="qritems" ref="qr_data_table" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr @click="editItem(item)" class="table-hover"
          :class="{ 'last-selected-row': idOfLastSelectedRowQRCodeDataTable === item.id }">
          <td class="row-text-no-wrap">
            {{ item.qrString === null ? item.qrWorkspaceId : item.qrString }}
          </td>
          <td class="row-text-no-wrap">
            {{ item.description }}
          </td>
          <td class="row-text-no-wrap">
            {{ item.location }}
          </td>
          <td class="row-text-no-wrap">
            {{ item.qrCategoryDescription }}
          </td>
          <td></td>
          <td>
            <div style="display: flex; align-items: center; justify-content: center; gap: 8px;">
              <v-tooltip text="Open QR-Code" location="top" :open-delay="tooltipOpenDelayDataTable">
                <template v-slot:activator="{ props }">
                  <a v-bind="props" @click.stop :href="item.url" target="_blank" class="data-table-button"
                    style="text-decoration: none;">
                    <div
                      style="display:flex; align-items:center; justify-content: center; min-width: 30px; min-height: 30px; size: 30px;">
                      <v-icon color="grey-darken-1" size="24px" class="py-2">mdi-form-select</v-icon>
                    </div>
                  </a>
                </template>
              </v-tooltip>
              <v-tooltip text="Open QR-Code Image" location="top" :open-delay="tooltipOpenDelayDataTable">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" @click.stop="openQRImageDialog(item)" class="data-table-button">
                    <div
                      style="display:flex; align-items:center; justify-content: center; min-width: 30px; min-height: 30px; size: 30px;">
                      <v-icon color="grey-darken-1" size="24px" class="py-2">mdi-qrcode</v-icon>
                    </div>
                  </div>
                </template>
              </v-tooltip>
              <v-tooltip text="Duplicate" location="top" :open-delay="tooltipOpenDelayDataTable">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" @click.stop="showcopydialog(item)" class="data-table-button">
                    <div
                      style="display:flex; align-items:center; justify-content: center; min-width: 30px; min-height: 30px; size: 30px;">
                      <v-icon color="grey-darken-1" size="24px" class="pa-2">mdi-file-multiple</v-icon>
                    </div>
                  </div>
                </template>
              </v-tooltip>
              <v-tooltip text="Delete" location="top" :open-delay="tooltipOpenDelayDataTable">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" @click.stop="deleteItem(item)" class="data-table-button">
                    <v-icon color="grey-darken-1" size="30px">mdi-delete</v-icon>
                  </div>
                </template>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="copydialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Generate copy(s) of {{ qrname }}</span>
          </v-card-title>
          <v-col cols="3">
            <v-text-field ref="copyamount" v-model="copyamount" label="No. copies" type="number"></v-text-field>
            <!-- This text field prevents default form behavior, where the page is reloaded when pressing enter in a form that has only one text field -->
            <v-text-field style="display: none;"></v-text-field>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined class="mb-3" @click="copydialog = false"><v-icon small
                class="py-2 mr-1">mdi-close</v-icon>
              Cancel</v-btn>
            <v-btn tile outlined class="mb-3 mr-3" :disabled="copyamount > 999 || copyamount < 1"
              @click="generatecopies()"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
              Create copies</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="800px" ref="maindialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.editedIndex === -1 ? "New" : "Edit" }} - QR Code</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="mainform" v-model="isFormValid">
              <v-row>
                <v-col cols="3" v-if="islegacy">
                  <v-text-field required ref="qrid" v-model="editedItem.qrString" :rules="qrRules" hide-details
                    label="QRID"></v-text-field>
                </v-col>
                <v-col cols="3" v-else>
                  <v-text-field required ref="qrid" v-model="editedItem.qrWorkspaceId" :rules="qrRules" type="number"
                    hide-details min="1" label="QRID"></v-text-field>
                </v-col>
                <v-col cols="9">
                  <v-text-field v-model="editedItem.description" label="Description" hide-details></v-text-field>
                </v-col>
                <v-col cols="3" @click="qrCategoryChanged = true">
                  <v-combobox v-model="selectedQrCategory" :items="qrCategories" item-value="id" hide-details
                    item-title="description" required :rules="requiredRules" label="Category">
                    <v-list-item v-bind="item" :class="item.description === 'Uncategorized' &&
                      'uncategorized-list-item'
                      ">
                      {{ item.description }}
                    </v-list-item>
                  </v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-combobox v-model="selectedLocation" :items="locations" auto-select-first item-value="id"
                    hide-details item-title="name" label="Location" @change="clearCoordinates"
                    ref="locationcombobox"></v-combobox>
                </v-col>
                <v-col cols="1" style="display: flex; padding-left: 0px;">
                  <v-icon v-if="selectedLocation" @click="imageDialog = true" size="30px"
                    :disabled="selectedLocation.imageId === null"
                    style="color: var(--report-red); margin: auto 0; position: relative;">mdi-map-marker-radius</v-icon>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="editedItem.position" label="Location description" hide-details></v-text-field>
                </v-col>
                <v-col v-if="inventoryTypesText.length > 0" cols="7">
                  <div class="typeinfo" style="margin-left: 12px;">{{ this.inventoryTypesText }}</div>
                </v-col>
              </v-row>
              <v-row style="padding-top: 12px;">
                <v-col cols="12">
                  <div
                    style="display: flex; align-items: center; justify-content: space-between; padding: 0 12px 0px 12px; gap:4px">
                    <h3 style="font-weight: normal;">Inventory</h3>
                    <v-btn tile outlined @click="newqrdetail()" :disabled="!isFormValid"><v-icon medium
                        class="py-2 mr-2">mdi-plus</v-icon>Add</v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-data-table dense :headers="qrdetailheaders" :items="qrdetailitems" hide-default-footer
                    disable-pagination item-key="key" class="elevation-1">
                    <template v-slot:[`item`]="{ item }">
                      <tr @click="editqrdetail(item)" class="table-hover"
                        :class="{ 'last-selected-row': idOfLastSelectedRowInventoryDataTable === item.id }">
                        <td>{{ item.description }}</td>
                        <td>{{ item.inventoryTypeName }}</td>
                        <td>{{ item.aliasTypeDescription }}</td>
                        <td>
                          <div style="display: flex; align-items: center; justify-content: center; gap: 8px;">
                            <v-tooltip text="Remove" location="top" :open-delay="tooltipOpenDelayDataTable">
                              <template v-slot:activator="{ props }">
                                <div v-bind="props" @click.stop="deleteqrdetail(item)" class="data-table-button">
                                  <v-icon color="grey-darken-1" size="30px">mdi-delete</v-icon>
                                </div>
                              </template>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-if="useForms" style="padding-top: 12px;">
                <v-col cols="12">
                  <div style="display: flex; align-items: center; padding: 0 12px 0px 12px; gap:4px">
                    <h3 style="font-weight: normal;">Forms</h3>
                  </div>
                </v-col>
                <v-col class="d-flex" cols="12">
                  <v-checkbox v-model="editedItem.isContactRequestActive" hide-details
                    :color="editedItem.isContactRequestActive ? 'green-lighten-1' : 'grey-darken-1'"
                    label="Enable contact request."></v-checkbox>
                </v-col>
                <v-col class="d-flex" cols="12">
                  <v-checkbox v-model="editedItem.isFeedbackActive" hide-details
                    :color="editedItem.isFeedbackActive ? 'green-lighten-1' : 'grey-darken-1'"
                    label="Enable feedback."></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class=" mb-3" @click="close"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn class="mb-3 mr-3" @click="save" :disabled="!isFormValid"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon>
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog" max-width="60vw" eager>
      <v-card>
        <v-card-title>
          <span class="headline m-b-6">QR Location</span>
        </v-card-title>
        <v-container>
          <div class="map-area-general">
            <MapComponent v-if="
              selectedLocation != null &&
              selectedLocation.imageName &&
              imageDialog" :qrId="editedItem.id" :name="selectedLocation.imageName" :marker-draggable="true"
              :qrs="[{ qrId: editedItem.id, qrString: editedItem.qrString === null ? editedItem.qrWorkspaceId : editedItem.qrString, qrDescription: editedItem.description, latLng: editedItem.latLng }]"
              @updateLocation="updateLocationFromChild" />
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3 mr-3" @click="imageDialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrdetaildialog" max-width="600px" eager>
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.qrDetail.id === 0 ? "Add - Inventory" : "Edit - Inventory" }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValidDetail" ref="qrdetailform">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete v-model="selectedInventory" :items="inventoryitems.filter(ii => ii.isActive)"
                    @update:model-value="qrDetail.description = selectedInventory.description; qrDetail.inventoryId = selectedInventory.id; qrDetail.inventoryTypeName = selectedInventory.inventoryTypeName"
                    item-value="id" item-title="description" required :rules="requiredRules" label="Item" return-object>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field disabled v-model="qrDetail.inventoryTypeName"
                    label="Inventory Type Name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="qrDetail.aliasTypeDescription" label="Custom type description"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="qrdetaildialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="saveqrdetails()" :disabled="!isFormValidDetail"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon>
            Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrImageDialog" max-width="400px" eager>
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <span class="headline">QR-Image : {{ this.editedItem.description }}</span>
          <v-btn @click="qrImageDialog = false">
            <v-icon size="20px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <vue-qrcode ref="qr_code_image" :value="generateQRCodeUrl(this.editedItem)" :width="400"
              style="max-width: 300px; max-height: 300px;" :color="{ dark: '#000000ff', light: '#ffffffff' }"
              type="image/png"></vue-qrcode>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn block @click.stop="saveSingleQRImage" prepend-icon="mdi-download">Download QR-Code image</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exportQRImageDialog" max-width="800px" eager>
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <span class="headline">Export QR Codes as images</span>
          <v-btn @click="exportQRImageDialog = false">
            <v-icon size="20px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Select the QR codes you want to download as images.
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-data-table style="max-height: 600px; overflow-y: auto" class="elevation-1" hide-default-footer
              :items-per-page="-1" v-model="selectedQRCodesForImageExport" item-value="id" :items="qritems" hover
              fixed-header ref="qr_image_data_table" return-object :headers="getExportQrImageHeaders" disable-pagination
              show-select dense>
              <template v-slot:[`header.data-table-select`]="{ allSelected, selectAll, someSelected }">
                <div style="display: flex; justify-content: center;">
                  <v-checkbox-btn :indeterminate="someSelected && !allSelected" :model-value="allSelected"
                    color="green-lighten-1" @update:model-value="selectAll"
                    style="width: 100%; justify-content: center;"></v-checkbox-btn>
                </div>
              </template>
              <template v-slot:[`item`]="{ item, internalItem, isSelected, toggleSelect }">
                <tr @click="toggleSelect(internalItem)" style="cursor: pointer;">
                  <td>
                    <v-checkbox-btn :model-value="isSelected(internalItem)" color="green"
                      @update:model-value="toggleSelect(internalItem)"></v-checkbox-btn>
                  </td>
                  <td>{{ item.qrString || item.qrWorkspaceId }}</td>
                  <td>{{ item.description }}</td>
                  <td @click.stop>
                    <div style="display: flex; justify-content: center; margin-right: 21px;">
                      <vue-qrcode :id="(item.qrString || item.qrWorkspaceId) + '-qr-code'"
                        :value="generateQRCodeUrl(item)" :width="400" style="max-width: 30px; max-height: 30px;"
                        :color="{ dark: '#000000ff', light: '#ffffffff' }" type="image/png"></vue-qrcode>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click.stop="saveSelectedQRImages" block prepend-icon="mdi-download"
            :disabled="selectedQRCodesForImageExport.length === 0">Download QR-Code images</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";
import { mapGetters } from "vuex";
import MapComponent from "../../components/MapComponent.vue";
import Tooltip from "../../components/HelpTooltip.vue";
import ExportButton from "../../components/ExportButton.vue";
import defaultDataTableFilter from "../../helpers/defaultFilterForDataTables";
import VueQrcode from "vue-qrcode"
import JSZip from "jszip";


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "home",
  components: {
    MapComponent,
    Tooltip,
    ExportButton,
    VueQrcode,
  },
  data() {
    return {
      contactRequest: "Contact request",
      feedback: "Feedback",

      formDescription: "",
      formObject: [],

      qrCategoryChanged: false,
      qrCategoryReverted: false,

      dialog: false,
      qrdetaildialog: false,
      imageDialog: false,
      qrImageDialog: false,
      exportQRImageDialog: false,

      enableEscClear: true,
      qrname: "",
      copydialog: false,
      search: this.$route.query.inventoryId,
      defaultWorkspace: "",

      qritems: [],
      selectedQRCodesForImageExport: [],
      searchedItems: [],
      qrdetailheaders: [
        { title: "Name", key: "description" },
        { title: "Inventory Type", key: "inventoryTypeName" },
        { title: "Custom Type", key: "aliasTypeDescription" },
        { title: "Actions", key: "action", align: "center", width: 100, sortable: false },
      ],
      formHeaders: [
        { title: "Description", key: "description", sortable: false },
        { title: "", key: "isActive", align: "center", width: 100, sortable: false },
      ],
      editedItem: {},
      editedIndex: null,
      locations: [],
      selectedLocation: {},
      qrCategories: [],
      selectedQrCategory: null,
      copyamount: null,
      selectedqr: null,
      qrdetailitems: [],
      qrDetail: {},
      selectedInventory: null,
      inventoryitems: [],
      inventoryTypesText: "",
      inventoryTypesByCategory: [],
      defaultForms: [],
      isContactRequestActive: null,
      isFeedbackActive: null,
      idOfLastSelectedRowQRCodeDataTable: 0,
      idOfLastSelectedRowInventoryDataTable: 0,

      footerProps: { "items-per-page-options": [20, 50, 100] },
      modellitems: {},
      valid: true,
      isFormValid: false,
      isFormValidDetail: false,
      qrRules: [
        (v) => !!v || "QRID is required",
        (v) =>
          /^[a-öA-Ö0-9]+$/.test(v) ||
          "QRID can only contain letters and numbers",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipOpenDelayDataTable: 200,
      tooltipMessage: [
        "QR Codes",
        'This section allows you to manage QR codes. Click "New" to add a new code. Edit existing codes by clicking the edit icon (pen), duplicate a code with the duplicate icon, or delete it with the trash icon. All settings of the listed QR codes are searchable, such as a device name or location.',
      ],

      // eslint-disable-next-line no-undef
      clientUrl: process.env.VUE_APP_CLIENTURL,
      logoImage: null,
    };
  },
  computed: {
    ...mapGetters([
      "isadministrator",
      "issuperadministrator",
      "sort",
      "workspaceid",
      "islegacy",
      "workspaceitems",
      "useForms"
    ]),
    getExportQrImageHeaders() {
      return [
        ...(this.islegacy
          ? [{ title: "QRID", align: "left", key: "qrString", width: 100 }]
          : [{ title: "QRID", align: "left", key: "qrWorkspaceId", width: 60 }]),
        { title: "Description", key: "description" },
        { title: "QR-Code", key: "qrUrl", align: "center", width: 100 },
      ];
    },
    getQrHeaders() {
      return [
        ...(this.islegacy
          ? [{ title: "QRID", align: "left", key: "qrString", width: 100 }]
          : [{ title: "QRID", align: "left", key: "qrWorkspaceId", width: 60 }]),
        { title: "Description", key: "description", width: 200 },
        { title: "Location", key: "location", width: 200 },
        { title: "Category", key: "qrCategoryDescription", width: 200 },
        { title: "", key: "", sortable: false },
        { title: "Actions", key: "action", align: "center", width: 200, sortable: false },
      ];
    },
  },
  created() {
    this.getqr(this.workspaceid);
    this.sortOptions = this.sort.qrSort || this.sortOptions;
  },
  mounted() {
    document.addEventListener("keydown", this.clearSearchEsc);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.clearSearchEsc);
  },
  watch: {
    dialog(val) {
      val || this.close();

      if (val) this.enableEscClear = false;
      if (!val) {
        this.inventoryTypesText = "";
        setTimeout(() => {
          this.enableEscClear = true;
        }, 100);
      }
    },
    workspaceid(newValue) {
      this.getqr(newValue)
    },
    selectedQrCategory(val, oldVal) {
      if (!val) return;
      var self = this;
      self.editedItem.qrCategoryDescription = val.description;
      const isUncategorized = val.description.toString() === "Uncategorized";

      if (self.qrCategoryReverted) {
        self.qrCategoryReverted = false;
        return;
      }

      if (!self.qrCategoryChanged) {
        self.getInventoryTypes(val.id);
      } else {
        if (oldVal && self.qrdetailitems.length > 0 && !isUncategorized) {
          this.$root.$ConfirmDialog
            .open(
              "Change QR Category",
              "All current items will be removed. <br><br> <strong>Are you sure?</strong>",
              { color: "red" }
            )
            .then((response) => {
              if (response) {
                self.getInventoryTypes(val.id);
                self.qrdetailitems = [];
              } else {
                self.selectedQrCategory = oldVal;
                self.qrCategoryReverted = true;
              }
            });
        } else {
          self.getInventoryTypes(val.id);
        }
      }
    },
    qrdetaildialog(val) {
      if (!val) {
        this.selectedInventory = null;
        this.qrDetail = {};
        setTimeout(() => {
          this.$refs.qrid.focus();
        }, 100);
      }
    },
    imageDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.qrid.focus();
        }, 100);
      }
    },
    exportQRImageDialog(value) {
      if (!value) {
        this.selectedQRCodesForImageExport = []
      }
    },
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc };

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort;
          newObj.qrSort = objSort;
          this.$store.dispatch("updateSort", newObj);
        }
      },
    },
  },
  methods: {
    generateQRCodeUrl(qrCode) {
      let urlstring = qrCode.qrString;
      if (qrCode.qrString === null)
        urlstring = `${qrCode.workspaceId}/${qrCode.qrWorkspaceId}`;
      return this.clientUrl + '/m/' + urlstring
    },
    async saveSelectedQRImages() {
      const zip = new JSZip();
      this.selectedQRCodesForImageExport.forEach((qrCode) => {
        const imageSrc = document.getElementById((qrCode.qrString || qrCode.qrWorkspaceId) + "-qr-code").src
        if (!imageSrc) {
          console.error("QR Code image not found");
        } else {
          const base64Data = imageSrc.replace(/^data:image\/png;base64,/, "");
          zip.file(`${qrCode.workspaceName}-${(qrCode.qrString || qrCode.qrWorkspaceId)}.png`, base64Data, { base64: true }); // adds the image file to the zip file
        }
      })
      const zipData = await zip.generateAsync({
        type: "blob",
        streamFiles: true
      })
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(zipData);
      link.download = `selected-qr-codes.zip`;
      link.click();
    },
    saveSingleQRImage() {
      const image = this.$refs.qr_code_image.$el.src;
      if (!image) {
        console.error("QR Code canvas not found");
        return;
      }
      const link = document.createElement('a');
      link.href = image;
      link.download = `${this.editedItem.workspaceName}-${(this.editedItem.qrString || this.editedItem.qrWorkspaceId)}.png`;
      link.click();
    },
    async openQRImageDialog(qrCode) {
      this.editedItem = qrCode
      this.qrImageDialog = true
    },
    customFilterForQrCodes(value, search, item) {
      const resultDefault = defaultDataTableFilter(value, search)
      const resultQrCodesByInventoryId = this.filterQrCodesByInventoryId(value, search, item)
      return resultDefault || resultQrCodesByInventoryId;
    },
    filterQrCodesByInventoryId(value, search, item) {
      if (!search || value != item.raw.description || !value) {
        return false
      }
      for (let index = 0; index < item.raw.inventoryItemIds.length; index++) {
        if (item.raw.inventoryItemIds[index] == search) {
          return true
        }
      }
      return false;
    },
    getqr(workspaceid) {
      var self = this;
      if (Number(workspaceid) === -1) {
        self.qritems = []
        return
      }
      HTTP.get("/qr/get/" + workspaceid).then((response) => {
        self.defaultWorkspace = response.data.defaultWorkspace;
        self.qritems = response.data.qrs;
      }).catch(() => {
        self.qritems = []
        self.defaultWorkspace = -1
      })
    },
    async editItem(item) {
      var self = this;
      self.qrCategoryChanged = false;
      self.editedIndex = self.qritems.findIndex((q) => q.id === item.id);
      self.idOfLastSelectedRowQRCodeDataTable = item.id
      await HTTP.get("/qr/edit/" + item.id).then((response) => {
        self.editedItem = response.data.qr;
        self.qrCategories = response.data.qrCategories;
        self.qrdetailitems = response.data.qrDetails.map((item, index) => ({
          key: index,
          ...item,
        }));
        self.selectedQrCategory = self.qrCategories.find(
          (e) => e.id == self.editedItem.qrCategoryId
        );
        self.locations = response.data.locations;
        self.selectedLocation =
          self.locations.find((l) => l.id === self.editedItem.locationId) ||
          null;

        this.formObject = [
          {
            description: this.contactRequest,
            isActive: this.editedItem.isContactRequestActive,
          },
          {
            description: this.feedback,
            isActive: this.editedItem.isFeedbackActive,
          },
        ]
        this.isFeedbackActive = this.editedItem.isFeedbackActive
        this.isContactRequestActive = this.editedItem.isContactRequestActive
      }).catch(() => { })
      const isUncategorized =
        self.editedItem.qrCategoryDescription === "Uncategorized";
      if (this.workspaceid === -1) {
        return
      }
      await HTTP.get(`/qr/getinventory/${self.selectedQrCategory.id}/${isUncategorized}/${this.workspaceid}`)
        .then((response) => {
          self.inventoryitems = response.data;
        })
        .catch(() => { })
      this.dialog = true;
      setTimeout(() => {
        this.$refs.qrid.focus();
      }, 200);
    },
    editqrdetail(item) {
      this.idOfLastSelectedRowInventoryDataTable = item.id
      this.qrDetail = Object.assign({}, item);
      this.selectedInventory = this.inventoryitems.find(i => i.id === this.qrDetail.inventoryId);
      this.qrdetaildialog = true;
    },
    showcopydialog(item) {
      this.qrname = item.description;
      this.selectedqr = item;
      this.copydialog = true;
      setTimeout(() => {
        this.$refs.copyamount.focus();
      }, 200);
    },
    generatecopies() {
      this.copydialog = false;

      HTTP.post("/qr/copy", {
        id: this.selectedqr.id,
        copycount: this.copyamount,
      }).then(() => {
        this.getqr(this.workspaceid);
      }).catch(() => { })
    },
    getmodellitems() {
      HTTP.get("/inventory/getallmodeller").then((response) => {
        this.modellitems = response.data;
      }).catch(() => { })
    },
    getLocations() {
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get("/location/get/" + this.workspaceid).then((response) => {
        this.locations = response.data;
      }).catch(() => { })
    },
    deleteItem(item) {
      const index = this.qritems.indexOf(item);
      this.$root.$ConfirmDialog
        .open("Delete item", "Are you sure?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post("/qr/delete", { id: item.id })
              .then(() => {
                this.qritems.splice(index, 1);
              })
              .catch((e) => {
                this.$store.dispatch("showError", e.response.data);
              });
          }
        });
    },
    deleteqrdetail(item) {
      this.qrdetailitems.splice(item.key, 1);
    },
    close() {
      this.dialog = false;
    },
    newqr() {
      var self = this;
      if (self.workspaceid == -1) {
        self.$store.dispatch(
          "showError",
          "No workspace selected.<br/><br/>Please make sure you have created at least one workspace and that it is selected."
        );
        return;
      }
      HTTP.get("/qr/new/" + this.workspaceid).then((response) => {
        self.selectedLocation = null;
        self.editedItem = response.data.qr;
        self.editedItem.workspaceid = this.workspaceid;
        self.isContactRequestActive = response.data.qr.isContactRequestActive;
        self.isFeedbackActive = response.data.qr.isFeedbackActive;
        self.editedIndex = -1;
        self.qrCategories = response.data.qrCategories;
        response.data.qrCategories.find(qrc => qrc.description === "Uncategorized") !== null ? self.selectedQrCategory = response.data.qrCategories.find(qrc => qrc.description === "Uncategorized") : self.selectedQrCategory = null;
        self.qrdetailitems = response.data.qrDetails.map((item, index) => ({
          key: index,
          ...item,
        }));
        self.dialog = true;
        self.getLocations();
        if (self.$refs.mainform) {
          self.$refs.mainform.resetValidation();
        }
        this.formObject = [
          {
            description: this.contactRequest,
            isActive: this.editedItem.isContactRequestActive,
          },
          {
            description: this.feedback,
            isActive: this.editedItem.isFeedbackActive,
          },
        ]
        setTimeout(() => {
          self.$refs.qrid.focus();
        }, 200);
      }).catch(() => { })
    },
    newqrdetail() {
      var self = this;
      const isUncategorized =
        self.editedItem.qrCategoryDescription === "Uncategorized";
      self.qrDetail = {
        "id": 0,
        "inventoryId": 0,
        "description": null,
        "inventoryTypeName": null,
        "aliasTypeDescription": null
      };
      self.getInventoryByType(self.selectedQrCategory.id, isUncategorized);
      self.qrdetaildialog = true;
      if (this.$refs.qrdetailform) this.$refs.qrdetailform.resetValidation();
    },
    save() {
      var self = this;
      self.$refs.locationcombobox.blur();

      self.$nextTick(() => {
        let locationName = "";

        self.editedItem.qrCategoryId = self.selectedQrCategory.id;

        if (self.selectedLocation) {
          if (self.selectedLocation.id) {
            locationName = self.selectedLocation.name;
            self.editedItem.newLocation = null;
            self.editedItem.locationId = self.selectedLocation.id;
          } else {
            locationName = self.selectedLocation;
            self.editedItem.locationId = null;
            self.editedItem.newLocation = self.selectedLocation;
            self.editedItem.workspaceId = self.workspaceid;
          }
        } else {
          self.editedItem.locationId = null;
        }

        if (self.editedItem.position) {
          locationName !== ""
            ? (locationName += " - " + self.editedItem.position)
            : (locationName = self.editedItem.position);
        } else {
          self.editedItem.position = null;
        }

        HTTP.post("/qr/save", {
          qr: self.editedItem,
          qrDetails: self.qrdetailitems,
          qrCategories: [],
          locations: [],
        })
          .then((response) => {
            const qrUrl = self.islegacy
              ? self.editedItem.qrString
              : `${self.editedItem.workspaceId}/${self.editedItem.qrWorkspaceId}`;

            if (self.editedIndex > -1) {
              var currentitem = self.qritems[self.editedIndex];
              currentitem.qrString = self.editedItem.qrString;
              currentitem.description = self.editedItem.description;
              currentitem.location = locationName;
              currentitem.qrCategoryDescription = self.qrCategories.find(
                (t) => t.id === self.editedItem.qrCategoryId
              ).description;
              // eslint-disable-next-line
              currentitem.url = process.env.VUE_APP_CLIENTURL + "/m/" + qrUrl;
              currentitem.qrUrl =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/qrimage/" + qrUrl;
            } else {
              self.editedItem.id = response.data;
              self.editedItem.qrCategoryDescription = self.qrCategories.find(
                (t) => t.id === self.editedItem.qrCategoryId
              ).description;
              self.editedItem.location = locationName;
              self.editedItem.url =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/m/" + qrUrl;
              self.editedItem.qrUrl =
                // eslint-disable-next-line no-undef
                process.env.VUE_APP_CLIENTURL + "/qrimage/" + qrUrl;
              self.qritems.push(self.editedItem);
            }
          })
          .catch((e) => {
            this.$store.dispatch("showError", e.response.data);
          })
          .then(() => {
            this.dialog = false;
          });
      });
    },
    saveqrdetails() {
      console.log(this.qrDetail)
      if (this.qrDetail.key !== undefined) {
        var itemIndex = this.qrdetailitems.findIndex(qd => qd.key === this.qrDetail.key)
        this.qrdetailitems.splice(itemIndex, 1, this.qrDetail)
      } else {
        this.qrDetail.key = this.qrdetailitems.length
        this.qrdetailitems.push(this.qrDetail)
      }
      this.qrdetaildialog = false;
    },
    getInventoryByType(qrCategoryId, isUncategorized) {
      var self = this;
      if (this.workspaceid === -1) {
        return
      }
      HTTP.get(
        "/qr/getinventory/" +
        qrCategoryId +
        "/" +
        isUncategorized +
        "/" +
        self.workspaceid
      ).then((response) => {
        self.inventoryitems = response.data;
      }).catch(() => { })
    },
    getInventoryTypes(id) {
      this.inventoryTypesText = "";
      this.inventoryTypesByCategory = [];

      HTTP.get("/qrcategory/getinventorytypes/" + id).then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].qrCategory === id) {
            this.inventoryTypesByCategory.push(response.data[i].inventoryType);
          }
        }
        for (var j = 0; j < this.inventoryTypesByCategory.length; j++) {
          if (j <= this.inventoryTypesByCategory.length - 2) {
            this.inventoryTypesText += this.inventoryTypesByCategory[j] + ", ";
          } else {
            this.inventoryTypesText += this.inventoryTypesByCategory[j];
          }
        }
      }).catch(() => { })
    },
    filter(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase());
      });
    },
    switchContactRequestActive(item) {
      this.isContactRequestActive = item;
    },
    switchFeedbackActive(item) {
      this.isFeedbackActive = item;
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = "";
      } else if (e.keyCode === 13 && this.copydialog && this.copyamount > 0 && this.copyamount < 999) {
        this.generatecopies()
      }
    },
    updateLocationFromChild(value) {
      this.editedItem.latLng = value;
    },
    clearCoordinates() {
      this.editedItem.latLng = null;
    },
  },
};
</script>
