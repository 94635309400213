<template>
  <v-card>
    <div class="d-flex align-center" style="min-height: 150px; padding-left: 40px;">
      <RouterLink :to="{ name: 'AccountSignIn' }" class="d-flex" style="align-items: center;">
        <img src="./../assets/service-me-logo.png" style="max-height: 40px;">
      </RouterLink>
    </div>
    <v-progress-linear :active="loading" :indeterminate="loading" height="6" color="#572F7B"></v-progress-linear>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      menuitems: [],
      selectedOrganization: null,
      selectedWorkspace: undefined,
    }
  },
  computed: {
    ...mapGetters(["loading"])
  },
  created() {
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "AccountSignIn" })
    },
  }
}
</script>
