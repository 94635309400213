import { createWebHistory, createRouter } from "vue-router";
import AdminIssues from "@/views/Admin/AdminIssues.vue";
import AdminQr from "@/views/Admin/AdminQr.vue";
import AdminQrCategory from "@/views/Admin/AdminQrCategory.vue";
import AdminInventory from "@/views/Admin/AdminInventory.vue";
import AdminStatistics from "@/views/Admin/AdminStatistics.vue";
import AdminUsers from "@/views/Admin/AdminUsers.vue";
import AdminOrganization from "@/views/Admin/AdminOrganization.vue";
import AdminWorkspace from "@/views/Admin/AdminWorkspace.vue";
import AccountSignIn from "@/views/AccountSignIn";
import RecoverPassword from "@/views/RecoverPassword";
import ChangePassword from "@/views/ChangePassword";
import MobileSelect from "@/views/MobileSelect";
import MobileReport from "@/views/MobileReport";
import MobileForm from "@/views/MobileForm";
import QRImage from "@/views/QRImage";
import MobileEdit from "@/views/MobileEdit";
import MobileEditSuccess from "@/views/MobileEditSuccess";
import AdminForm from "@/views/Admin/AdminForm";
import AdminLocation from "@/views/Admin/AdminLocation";
import MapView from "@/views/MapView";
import MobileLogin from "../views/MobileLogin.vue";
import MobileMain from "../views/MobileMain.vue";
import MobileReportSuccess from "../views/MobileReportSuccess.vue";
import AdminSystemInventoryType from "../views/Admin/AdminSystemInventoryType.vue";
import AdminMain from "../views/Admin/AdminMain.vue";
import store from "../store";
import HTTP from "../plugins/backendAPI";
import { logout } from "../helpers/logoutHandler";
import AccountRegistration from "../views/AccountRegistration.vue";
import AuthCallback from "../views/AuthCallback.vue";
import SSOWelcome from "../views/Admin/SSOWelcome.vue";

const routes = [
  {
    path: "/",
    redirect: "/issues",
  },

  {
    path: "/",
    component: AdminMain,
    meta: { requiresAuth: true },
    children: [
      {
        path: "issues",
        name: "Issues",
        component: AdminIssues,
        props: true,
      },
      {
        path: "qr",
        name: "qr",
        component: AdminQr,
        props: true,
      },
      {
        path: "qrcategory",
        name: "Qrcategory",
        component: AdminQrCategory,
      },
      {
        path: "form",
        name: "Form",
        component: AdminForm,
        beforeEnter: () => {
          if (!store.getters.useForms || store.getters.useForms === undefined) {
            store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting...");
            return { path: "/issues" };
          }
        },
      },
      {
        path: "location",
        name: "Location",
        component: AdminLocation,
      },
      {
        path: "inventory",
        name: "Inventory",
        component: AdminInventory,
      },
      {
        path: "statistics",
        name: "Statistics",
        component: AdminStatistics,
      },
      {
        path: "users",
        name: "Users",
        component: AdminUsers,
        meta: { requiresAdmin: true },
      },
      {
        path: "workspace",
        name: "Workspace",
        component: AdminWorkspace,
        meta: { requiresAdmin: true },
      },
      {
        path: "inventorytype/system",
        name: "SystemInventoryType",
        component: AdminSystemInventoryType,
        meta: { requiresSuperAdmin: true },
      },
      {
        path: "organization",
        name: "Organization",
        component: AdminOrganization,
        meta: { requiresSuperAdmin: true },
      },
      {
        path: "/sso/welcome",
        name: "SSOWelcome",
        component: SSOWelcome,
        beforeEnter: () => {
          if (!["SAML", "OIDC"].includes(store.getters.ssoAuthMethod)) {
            return { path: "/issues" };
          }
          if (
            store.getters.isadministrator ||
            store.getters.issuperadministrator ||
            store.getters.hasWorkspaceAuthorization
          ) {
            return { path: "/issues" };
          }
          console.log(store.getters.hasWorkspaceAuthorization);
        },
      },
    ],
  },
  {
    path: "/m/:qrstring",
    props: true,
    component: MobileMain,
    beforeEnter: async (to, from) => {
      if (store.getters.isQrCodeRegistered === undefined || from.name === undefined) {
        await HTTP.get(
          `/mobil/checkifqrexists/${to.params.qrstring}/${to.params.workspaceid}/${to.params.qrworkspaceid}`
        )
          .then(async (response) => {
            await store.dispatch("saveQrCodeStatus", response.data.isFound);
            await store.dispatch("doesOrganizationUseForms", response.data.useForms);
            if (response.data.isUsePinCode) {
              await store.dispatch("usePinCodeForMobileAuth");
            } else {
              await store.dispatch("dontUsePinCodeForMobileAuth");
            }
          })
          .catch(async (err) => {
            console.log(err + "hello");
            await store.dispatch("showError", err.response.data);
          });
      }
      if (to.name === undefined) {
        console.log("Pushing to select!!");
        return {
          name: "MobileSelectLegacy",
          params: { qrstring: to.params.qrstring },
        };
      }
    },
    children: [
      {
        path: "select",
        props: true,
        name: "MobileSelectLegacy",
        component: MobileSelect,
        beforeEnter: (to) => {
          if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileLegacyEdit",
              params: { qrstring: to.params.qrstring },
            };
          } else if (store.getters.isusepincodeformobileauth && !store.getters.ismobileauthorized) {
            console.log("Pushing to login!!");
            return {
              name: "MobileLegacyLogin",
              params: { qrstring: to.params.qrstring },
            };
          }
        },
      },
      {
        path: "login",
        props: true,
        name: "MobileLegacyLogin",
        component: MobileLogin,
        beforeEnter: (to) => {
          if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileLegacyEdit",
              params: { qrstring: to.params.qrstring },
            };
          } else if (
            (store.getters.isusepincodeformobileauth && store.getters.ismobileauthorized) ||
            !store.getters.isusepincodeformobileauth
          ) {
            console.log("Pushing to select!!");
            return {
              name: "MobileSelectLegacy",
              params: { qrstring: to.params.qrstring },
            };
          }
        },
      },
      {
        path: "report/:qrdetailid",
        props: true,
        name: "MobileLegacyReport",
        component: MobileReport,
        beforeEnter: (to) => {
          if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileLegacyEdit",
              params: { qrstring: to.params.qrstring },
            };
          } else if (store.getters.isusepincodeformobileauth && !store.getters.ismobileauthorized) {
            console.log("Pushing to login!!");
            return {
              name: "MobileLegacyLogin",
              params: { qrstring: to.params.qrstring },
            };
          }
        },
      },
      {
        path: "form/:formtype",
        props: true,
        name: "MobileLegacyForm",
        component: MobileForm,
        beforeEnter: (to) => {
          console.log(to);
          if (!store.getters.useForms) {
            store.dispatch("showError", "The qr code does not support the usage of forms.");
            console.log("Pushing to select!!");
            return {
              name: "MobileSelectLegacy",
              params: { qrstring: to.params.qrstring },
            };
          } else if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileLegacyEdit",
              params: { qrstring: to.params.qrstring },
            };
          } else if (store.getters.isusepincodeformobileauth && !store.getters.ismobileauthorized) {
            console.log("Pushing to login!!");
            return {
              name: "MobileLegacyLogin",
              params: { qrstring: to.params.qrstring },
            };
          }
        },
      },
      {
        path: "edit",
        name: "MobileLegacyEdit",
        props: true,
        component: MobileEdit,
        meta: { requiresAuth: true },
      },
      {
        path: "report/success",
        name: "MobileLegacyReportSuccess",
        props: true,
        component: MobileReportSuccess,
      },
      {
        path: "form/success",
        name: "MobileLegacyFormSuccess",
        props: true,
        component: MobileReportSuccess,
      },
      {
        path: "edit/success",
        name: "MobileLegacyEditSuccess",
        props: true,
        component: MobileEditSuccess,
      },
    ],
  },
  {
    path: "/m/:workspaceid/:qrworkspaceid",
    props: true,
    component: MobileMain,
    beforeEnter: async (to, from) => {
      if (store.getters.isQrCodeRegistered === undefined || from.name === undefined) {
        await HTTP.get(
          `/mobil/checkifqrexists/${to.params.qrstring}/${to.params.workspaceid}/${to.params.qrworkspaceid}`
        )
          .then(async (response) => {
            await store.dispatch("saveQrCodeStatus", response.data.isFound);
            await store.dispatch("doesOrganizationUseForms", response.data.useForms);
            if (response.data.isUsePinCode) {
              await store.dispatch("usePinCodeForMobileAuth");
            } else {
              await store.dispatch("dontUsePinCodeForMobileAuth");
            }
          })
          .catch(async (err) => {
            console.log(err + "hello");
            await store.dispatch("showError", err.response.data);
          });
      }
      if (to.name === undefined) {
        console.log("Pushing to select!!");
        return {
          name: "MobileSelect",
          params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
        };
      }
    },
    children: [
      {
        path: "select",
        props: true,
        name: "MobileSelect",
        component: MobileSelect,
        beforeEnter: (to) => {
          if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileEdit",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          } else if (store.getters.isusepincodeformobileauth && !store.getters.ismobileauthorized) {
            console.log("Pushing to login!!");
            return {
              name: "MobileLogin",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          }
        },
      },
      {
        path: "login",
        props: true,
        name: "MobileLogin",
        component: MobileLogin,
        beforeEnter: (to) => {
          if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileEdit",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          } else if (
            (store.getters.isusepincodeformobileauth && store.getters.ismobileauthorized) ||
            !store.getters.isusepincodeformobileauth
          ) {
            console.log("Pushing to select!!");
            return {
              name: "MobileSelect",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          }
        },
      },
      {
        path: "report/:qrdetailid",
        props: true,
        name: "MobileReport",
        component: MobileReport,
        beforeEnter: (to) => {
          if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileEdit",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          } else if (store.getters.isusepincodeformobileauth && !store.getters.ismobileauthorized) {
            console.log("Pushing to login!!");
            return {
              name: "MobileLogin",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          }
        },
      },
      {
        path: "form/:formtype",
        props: true,
        name: "MobileForm",
        component: MobileForm,
        beforeEnter: (to) => {
          console.log(to);
          if (!store.getters.useForms) {
            store.dispatch("showError", "The qr code does not support the usage of forms.");
            console.log("Pushing to select!!");
            return {
              name: "MobileSelect",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          } else if (!store.getters.isQrCodeRegistered) {
            console.log("Pushing to edit!!");
            return {
              name: "MobileEdit",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          } else if (store.getters.isusepincodeformobileauth && !store.getters.ismobileauthorized) {
            console.log("Pushing to login!!");
            return {
              name: "MobileLogin",
              params: { workspaceid: to.params.workspaceid, qrworkspaceid: to.params.qrworkspaceid },
            };
          }
        },
      },
      {
        path: "edit",
        name: "MobileEdit",
        props: true,
        component: MobileEdit,
        meta: { requiresAuth: true },
      },
      {
        path: "report/success",
        name: "MobileReportSuccess",
        props: true,
        component: MobileReportSuccess,
      },
      {
        path: "form/success",
        name: "MobileFormSuccess",
        props: true,
        component: MobileReportSuccess,
      },
      {
        path: "edit/success",
        name: "MobileEditSuccess",
        props: true,
        component: MobileEditSuccess,
      },
    ],
  },
  {
    path: "/displaymap/:qrCodeId",
    name: "MapPage",
    props: true,
    component: MapView,
  },
  {
    path: "/sign-in",
    name: "AccountSignIn",
    props: true,
    component: AccountSignIn,
    beforeEnter: async () => {
      if (await checkAuth("auth")) {
        return {
          name: "Issues",
        };
      }
    },
  },
  {
    path: "/registration",
    name: "AccountRegistration",
    props: true,
    component: AccountRegistration,
    beforeEnter: async (to) => {
      console.log(to);
      if (to.query["auth-method"] === undefined || to.query["entra-group-id"] === undefined) {
        await logout();
      }
    },
  },
  {
    path: "/auth/callback",
    name: "AuthCallback",
    props: true,
    component: AuthCallback,
  },
  {
    path: "/recover-password",
    name: "RecoverPassword",
    component: RecoverPassword,
  },
  {
    path: "/changepassword/:guid",
    name: "ChangePassword",
    props: true,
    component: ChangePassword,
  },
  {
    path: "/qrimage/:qrstring",
    name: "QRImageLegacy",
    props: true,
    meta: { requiresAuth: true },
    component: QRImage,
  },
  {
    path: "/qrimage/:workspaceid/:qrworkspaceid",
    name: "QRImage",
    props: true,
    meta: { requiresAuth: true },
    component: QRImage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  console.log(to.name);
  console.log("MAYBE MAYBE MAYBE");
  console.log(!!to.meta.requiresAuth);
  console.log(!!to.meta.requiresAdmin);
  console.log(!!to.meta.requiresSuperAdmin);

  if (!!to.meta.requiresAuth && (store.getters.ssoAuthMethod === null || store.getters.ssoAuthMethod === undefined)) {
    HTTP.defaults.headers.common["Authorization"] = "Bearer " + store.getters.bearertoken;
    await HTTP.get("/users/get/logged-in-user")
      .then((response) => {
        store.dispatch("saveAuthorization", {
          username: response.data.email,
          isauthorized: true,
          bearertoken: HTTP.defaults.headers.common["Authorization"].substring("bearer ".length),
          isadministrator: response.data.isAdministrator,
          issuperadministrator: response.data.isSuperAdministrator,
          isOwner: response.data.role === 1 ? true : false,
          hasWorkspaceAuthorization: response.data.hasWorkspaceAuthorization,
        });
        store.dispatch("saveSSOAuthMethod", response.data.ssoAuthMethod);
        if (
          !response.data.isadministrator &&
          !response.data.isSuperAdministrator &&
          !response.data.hasWorkspaceAuthorization
        ) {
          return { name: "SSOWelcome" };
        }
      })
      .catch(async (e) => {
        console.log(e);
        if (store.getters.isauthorized) {
          await logout();
        }
      });
  }
  if (
    !!to.meta.requiresAuth &&
    to.name !== "SSOWelcome" &&
    ["SAML", "OIDC"].includes(store.getters.ssoAuthMethod) &&
    !store.getters.isadministrator &&
    !store.getters.issuperadministrator &&
    !store.getters.hasWorkspaceAuthorization
  ) {
    return { name: "SSOWelcome" };
  }

  if (!!to.meta.requiresAuth && !(await checkAuth("auth"))) {
    console.log("NO NO NO");
    store.dispatch("setRedirectRoute", to.fullPath);
    await logout();
  }
  if (!!to.meta.requiresAdmin && !(await checkAuth("admin"))) {
    await store.dispatch("showError", "You're account is not authorized for this page. <br><br> Redirecting...");
    return { name: "Issues" };
  }
  if (!!to.meta.requiresSuperAdmin && !(await checkAuth("super-admin"))) {
    await store.dispatch("showError", "You're account is not authorized for this page. <br><br> Redirecting...");
    return { name: "Issues" };
  }
});

async function checkAuth(authLevel) {
  if (HTTP.defaults.headers.common["Authorization"] === undefined) {
    HTTP.defaults.headers.common["Authorization"] = `Bearer ${store.getters.bearertoken}`;
  }
  if (store.getters.isauthorized === undefined) {
    return false;
  } else if (authLevel === "admin" || authLevel === "super-admin") {
    let userAuthorized = false;
    await HTTP.get("/users/auth/roles")
      .then((response) => {
        if (authLevel === "admin" && !response.data.isAdmin && !response.data.isSuperAdmin) {
          userAuthorized = false;
        } else if (authLevel === "super-admin" && !response.data.isSuperAdmin) {
          userAuthorized = false;
        } else {
          userAuthorized = true;
        }
      })
      .catch(() => {
        userAuthorized = false;
      });
    return userAuthorized;
  } else if (authLevel === "auth" && store.getters.isauthorized) {
    return true;
  } else {
    return false;
  }
}

export default router;
