<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">Organizations</h2>
      <v-btn v-if="issuperadministrator" @click="sendInvoiceBasisEmail()" color="white">
        <v-icon>mdi-email</v-icon>
        <span class="ml-2">Send Invoice Data</span>
      </v-btn>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col cols=3>
        <v-btn color="white" @click="newitem()"><v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New</v-btn>
      </v-col>
    </v-row>
    <v-data-table :search="search" dense :headers="headers" :items="tableItems" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr @click="editItem(item)" class="table-hover"
          :class="{ 'last-selected-row': idOfLastSelectedRow === item.id }">
          <td class="row-text-no-wrap">
            {{ item.name }}
          </td>
          <td class="row-text-no-wrap">
            {{ item.organizationOwner.firstname }} {{ item.organizationOwner.lastname }}
          </td>
          <td></td>
          <td>
            <div style="display: flex; justify-content: center;">
              <v-icon color="green-darken-1" hide-details v-if="item.useEmailRequirement"
                style="margin-right: 21px;">mdi-check</v-icon>
            </div>
          </td>
          <td>
            <div style="display: flex; justify-content: center;">
              <v-icon color="green-darken-1" hide-details v-if="item.useForms"
                style="margin-right: 21px;">mdi-check</v-icon>
            </div>
          </td>
          <td>
            <div style="display: flex; justify-content: center;">
              <v-icon color="green-darken-1" hide-details v-if="item.useLanguageOptions"
                style="margin-right: 21px;">mdi-check</v-icon>
            </div>
          </td>
          <td>
            <div style="display: flex; justify-content: center;">
              <v-icon color="green-darken-1" hide-details v-if="item.useEntraIdSSO"
                style="margin-right: 21px;">mdi-check</v-icon>
            </div>
          </td>
          <td>
            <div v-if="!item.isSuperAdminOrganization"
              style="display: flex; align-items: center; justify-content: center; margin-right: 21px">
              <div @click.stop="saveSendInvoiceDataUpdate(item)" class="data-table-button">
                <div
                  style="display:flex; align-items:center; justify-content: center; min-width: 30px; min-height: 30px; size: 30px;">
                  <v-icon :color="item.sendInvoiceData ? 'green-lighten-1' : 'grey-darken-1'" size="24px"
                    v-model="item.sendInvoiceData">{{
                      item.sendInvoiceData
                        ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center; justify-content: center;">
              <v-tooltip :text="item.isSuperAdminOrganization ? 'Admin organization can\'t be deleted' : 'Delete'"
                location="top" :open-delay="tooltipOpenDelayDataTable">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" @click.stop="item.isSuperAdminOrganization ? '' : deleteItem(item)"
                    :class="item.isSuperAdminOrganization ? 'data-table-button-disabled' : 'data-table-button'">
                    <v-icon :disabled="item.isSuperAdminOrganization" color="grey-darken-1"
                      size="30px">mdi-delete</v-icon>
                  </div>
                </template>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="800px" eager>
      <v-card>
        <v-card-title>
          <h3 class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }} - Organization</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValid" ref="organizationform">
              <v-row>
                <v-col cols="12">
                  <v-text-field required :rules="reqRules" v-model="editedItem.name" label="Name"
                    hide-details></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" style="align-items: center;">
                  <v-btn tile outlined @click="newImageDialog = true"><v-icon medium
                      class="py-2 mr-2">mdi-upload</v-icon>Upload logo</v-btn>
                  <p v-if="this.uploadedImage != null"
                    style="margin: auto; margin-right: 6px; margin-left: 12px; font-size: medium;">
                    {{
                      this.uploadedImage.filename
                    }}</p>
                  <v-icon v-if="this.uploadedImage != null"
                    @click="clearFileInput(); editedItem.imagePath = null">mdi-close</v-icon>
                </v-col>
                <v-col class="d-flex" cols="12">
                  <v-checkbox v-model="editedItem.sendInvoiceData" hide-details color="green-lighten-1"
                    label="Include this organization when sending invoice data"></v-checkbox>
                </v-col>
                <v-col class="d-flex" cols="12">
                  <v-checkbox v-model="editedItem.useForms" hide-details color="green-lighten-1"
                    label="Enable Forms"></v-checkbox>
                </v-col>
                <v-col class="d-flex" cols="12">
                  <v-checkbox v-model="editedItem.useLanguageOptions" hide-details color="green-lighten-1"
                    label="Enable Language option in Mobile"></v-checkbox>
                </v-col>
                <v-col v-if="editedItem.useLanguageOptions" cols="12">
                  <v-select required :rules="reqRules" :items="languageOptions" item-title="displayName"
                    item-value="key" v-model="editedItem.languageOption" label="Language option"></v-select>
                </v-col>

                <v-col cols="12">
                  <v-checkbox v-model="editedItem.useEntraIdSSO" hide-details color="green-lighten-1"
                    label="Enable Microsoft SSO"></v-checkbox>
                </v-col>
                <v-col v-if="editedItem.useEntraIdSSO" cols="12">
                  <v-select required :rules="reqRules" :items="ssoAuthMethodOptions" v-model="editedItem.ssoAuthMethod"
                    item-title="title" item-value="value" label="SSO Auth Method"></v-select>
                  <v-text-field v-if="!!editedItem.ssoAuthMethod && !!editedItem.useEntraIdSSO" required
                    :rules="reqRules" ref="entraGroupId" v-model="editedItem.entraGroupId"
                    :disabled="editedItem.ssoAuthMethod === null" label="Entra Group ID"></v-text-field>
                  <v-text-field
                    v-if="!!editedItem.entraGroupId && !!editedItem.ssoAuthMethod && !!editedItem.useEntraIdSSO && !!editedItem.registrationUrl"
                    v-model="editedItem.registrationUrl" label="Account Registration Url" readonly></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox v-model="editedItem.useEmailRequirement" hide-details color="green-lighten-1"
                    label="Enable Email requirements in Mobile"></v-checkbox>
                </v-col>
                <v-col v-if="editedItem.useEmailRequirement" cols="12">
                  <div v-for="(requirement, index) in emailRequirements" :key="index"
                    style="display: grid; grid-template-columns: 25% 75%;">
                    <v-select v-if="index % 2 === 1" required :rules="reqRules"
                      @click="updateAvailableRegexConditionalOptions(index)" :disabled="!requirement.isEditable"
                      :items="regexConditionalOptions" v-model="requirement.type" label="And/Or"></v-select>
                    <v-select v-else required :rules="reqRules" @click="updateAvailableRegexOptions(index)"
                      :disabled="!requirement.isEditable" :items="regexOptions" v-model="requirement.type"
                      label="Type"></v-select>
                    <div v-if="index % 2 === 1"></div>
                    <div v-else style="display: flex;">
                      <v-text-field required :rules="reqRules" :disabled="!requirement.isEditable"
                        v-model="requirement.description" label="Input" style="margin-left: 12px;"></v-text-field>
                      <div style="display: flex; flex-direction: column; align-items: end;">
                        <v-icon v-if="index >= 1" @click="removeEmailRequirementAt(index)"
                          :disabled="!requirement.isEditable" size="30px" style=" margin: auto 12px"
                          color="grey-darken-1">
                          mdi-close-box-outline
                        </v-icon>
                        <div style="min-height: 22px;"></div>
                      </div>
                      <!-- <v-icon v-if="index >= 1" @click="removeEmailRequirementAt(index)">mdi-close</v-icon> -->
                    </div>
                  </div>
                  <v-btn prepend-icon="mdi-plus" @click="addEmailRequirement" style="margin-bottom: 22px">Add
                    Condition</v-btn>
                  <v-text-field v-model="editedItem.emailRequirementHint" label="Hint"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12">
                  <h3 class="headline">Organization Owner</h3>
                </v-col>
                <v-col cols="12">
                  <v-btn v-if="doesOrganizationHaveAnOwner" color="white" @click="editOwner()">
                    Edit Owner
                  </v-btn>
                  <v-btn v-else color="white" @click="editOwner()">
                    Create Owner
                  </v-btn>
                  <v-btn color="white" @click="selectOwner()">
                    Select Owner
                  </v-btn>
                </v-col>
                <v-col v-if="!doesOrganizationHaveAnOwner" cols="12">
                  <v-alert type="error" style="padding: 12px 12px 12px 12px; width: 100%; z-index: 1;">Owner is
                    required. Register an owner before proceeding.</v-alert>
                </v-col>
                <v-col v-if="editedItem.organizationOwner !== undefined" cols="12">
                  <v-select v-if="isSelectOwner" v-model="selectedOrganizationOwner" :items="organizationOwners"
                    :item-title="(owner) => { return `${owner.firstname} ${owner.lastname} (${owner.email})` }"
                    label="Owners" return-object></v-select>
                  <v-text-field v-if="isEditOwner" required :rules="reqRules" ref="firstName"
                    v-model="editedItem.organizationOwner.firstname" label="First name"></v-text-field>
                  <v-text-field v-if="isEditOwner" required :rules="reqRules"
                    v-model="editedItem.organizationOwner.lastname" label="Last name"></v-text-field>
                  <v-text-field v-if="isEditOwner" required :rules="emailRules" validate-on-blur
                    v-model="editedItem.organizationOwner.email" label="User name / e-mail"></v-text-field>
                  <v-text-field v-if="!doesOrganizationHaveAnOwner && isEditOwner"
                    v-model="editedItem.organizationOwner.password"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="newPassRules"
                    :type="showpassword ? 'text' : 'password'" label="Password"
                    hint="At least 15 characters, 1 digit, 1 special character" counter
                    @click:append="showpassword = !showpassword" autocomplete="off"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="save()"
            :disabled="!isFormValid || !isOrganizationOwnerValid"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon>
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newImageDialog" max-width="600px" eager>
      <v-card>
        <v-card-title>
          Select image(s)
        </v-card-title>
        <v-container>
          <form id="image-dropbox-form" enctype="multipart/form-data" novalidate>
            <div class="dropbox">
              <input id="file-input" type="file" multiple name="photos" accept="image/*" class="input-file"
                @change="filesChange($event)">
              <p v-if="!isUploadingFiles" class="upload-text">Drag your file here</p>
              <p v-if="!isUploadingFiles" class="upload-text">or click to browse.</p>
              <p v-if="!isUploadingFiles && this.uploadedImage == null" class="upload-text file-count">
                File selected:
              </p>
              <p v-if="!isUploadingFiles && this.uploadedImage != null" class="upload-text file-count">File
                selected: {{
                  this.uploadedImage.filename
                }}</p>

              <!-- {{ uploadedImage
                      === null ?
                      " no file selected" : uploadedImage.filename }} -->
              <p v-if="isUploadingFiles" class="upload-text">Uploading image...</p>
            </div>
          </form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="newImageDialog = false; clearFileInput()"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="newImageDialog = false" :disabled="!isImageFormValid"><v-icon
              small class="py-2 mr-1">mdi-content-save</v-icon> Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';
import { EmailRegexBuilder, EmailRegexObjectBuilder } from '../../helpers/emailRegexBuilder';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

// (?=.*\d) ensures at least one digit exists
// (?=.*[^\w\såäöæøÅÄÖÆØ]) ensures at least one special character exists, excluding Nordic letters without duplication.
// .{15,} ensures at least 15 characters of any type
const passWdRegex = /^(?=.*\d)(?=.*[^\w\såäöæøÅÄÖÆØ]).{15,}$/;

export default {
  data() {
    return {
      languageOptions: [{ displayName: 'English', key: 0 }, { displayName: 'Swedish', key: 1 }],
      isImageFormValid: false,
      organizationOwners: [],
      selectedOrganizationOwner: null,
      isEditOwner: false,
      isSelectOwner: false,
      dialog: false,
      newImageDialog: false,
      isUploadingFiles: false,
      search: '',
      footerProps: { 'items-per-page-options': [50, 100] },
      headers: [
        { title: 'Name', key: 'name', width: 200 },
        { title: 'Owner name', key: 'firstname', width: 200 },
        { title: '', key: '', sortable: false },
        { title: 'Email requirements', key: 'useEmailRequirement', align: "center", width: 150 },
        { title: 'Forms', key: 'useForms', align: "center", width: 100 },
        { title: 'Language Option', key: 'languageOption', align: "center", width: "100" },
        { title: 'SSO', key: 'useEntraIdSSO', align: "center", width: 100 },
        { title: 'Invoice data', key: 'sendInvoiceData', align: "center", width: 100 },
        { title: '', key: 'action', align: "center", width: 100, sortable: false }
      ],

      emailRequirements: [{ type: null, description: "", isEditable: true }],
      regexOptions: ["Starts with", "Contains", "Ends with"],
      regexConditionalOptions: ["AND", "OR"],

      ssoAuthMethodOptions: [{ title: "OIDC", value: 1 }, { title: "SAML", value: 2 }],

      idOfLastSelectedRow: 0,
      tableItems: [],
      editedItem: {},
      uploadedImage: null,
      logoImage: null,
      organizationId: -1,
      newItem: false,
      showpassword: false,
      newPassRules: [
        v => !!v || 'Field is required',
        v => !!passWdRegex.test(v) || 'At least 15 characters, 1 digit, 1 special character',
      ],
      editPassRules: [
        v => (!!v === false || v !== null && !!passWdRegex.test(v)) || 'At least 15 characters, 1 digit, 1 special character',
      ],
      reqRules: [
        v => !!v || v === 0 || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'Field is required',
        v => !!emailRegex.test(v) || 'Email is in incorrect format',
      ],
      isFormValid: false,

      tooltipOpenDelayDataTable: 200,
    }
  },
  created() {
    this.getitems()
    this.getOwners()
  },
  computed: {
    ...mapGetters([
      "issuperadministrator",
      "organizationid"
    ]),
    isOrganizationOwnerValid() {
      try {
        if (this.isSelectOwner) {
          return this.selectedOrganizationOwner !== null
        }
        else if (this.editedItem.organizationOwner.id > 0) {
          return this.editedItem.organizationOwner.firstname !== null && this.editedItem.organizationOwner.lastname !== null && this.editedItem.organizationOwner.email !== null
        }
        return this.editedItem.organizationOwner.firstname !== null && this.editedItem.organizationOwner.lastname !== null && this.editedItem.organizationOwner.email !== null && this.editedItem.organizationOwner.password !== null

      } catch (error) {
        return false
      }
    },
    doesOrganizationHaveAnOwner() {
      try {
        return this.editedItem.organizationOwner.id > 0
      } catch (error) {
        return false
      }
    },
  },
  methods: {
    updateAvailableRegexConditionalOptions(index) {
      this.regexConditionalOptions = []
      if (this.emailRequirements[index - 1].type === "Ends with" || this.emailRequirements[index - 1].type === "Starts with" && this.emailRequirements[index + 1].type === "Starts with") {
        this.regexConditionalOptions.push("OR")
      } else {
        this.regexConditionalOptions.push("AND")
        this.regexConditionalOptions.push("OR")
      }
    },
    updateAvailableRegexOptions(index) {
      this.regexOptions = []
      if (index === 0) {
        this.regexOptions.push("Starts with")
        this.regexOptions.push("Contains")
        this.regexOptions.push("Ends with")
        return
      }
      if (this.emailRequirements[index - 1].type === "OR") {
        this.regexOptions.push("Starts with")
        this.regexOptions.push("Contains")
        this.regexOptions.push("Ends with")
        return
      } else if (this.emailRequirements[index - 1].type !== "OR" && this.emailRequirements[index - 2].type === "Ends with") {
        this.regexOptions = []
        return
      }
      if (this.emailRequirements[index - 1].type === "AND") {
        this.regexOptions.push("Contains")
        this.regexOptions.push("Ends with")
      }
    },
    removeEmailRequirementAt(index) {
      this.emailRequirements.splice(index - 1, 2)
      if (this.emailRequirements.length > 1) {
        this.emailRequirements[this.emailRequirements.length - 2].isEditable = true
        this.emailRequirements[this.emailRequirements.length - 1].isEditable = true
      } else {
        this.emailRequirements[this.emailRequirements.length - 1].isEditable = true
      }
    },
    addEmailRequirement() {
      this.emailRequirements.forEach((requirement) => {
        requirement.isEditable = false
      })
      this.emailRequirements.push({ type: null, description: "", isEditable: true })
      this.emailRequirements.push({ type: null, description: "", isEditable: true })
    },
    getOwners() {
      HTTP.get('organization/get/owners').then((response) => {
        this.organizationOwners = response.data
      })
    },
    selectOwner() {
      if (this.isSelectOwner) {
        this.isSelectOwner = false
        this.isEditOwner = false
      } else {
        this.isSelectOwner = true
        this.isEditOwner = false
      }
    },
    editOwner() {
      if (this.isEditOwner) {
        this.isEditOwner = false
        this.isSelectOwner = false
      } else {
        this.isEditOwner = true
        this.isSelectOwner = false
      }
    },
    filesChange(e) {
      if (!e.target.files.length) {
        this.isImageFormValid = false
        return;
      }
      this.isImageFormValid = true
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const logoImageToUpload = {
          filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
        this.uploadedImage = logoImageToUpload
      }
      reader.readAsDataURL(file);
    },
    async saveImage() {
      this.isUploadingFiles = true
      if (this.uploadedImage.filedata != undefined) {
        await HTTP.post(`/organization/${this.organizationId}/save/logo`, this.uploadedImage).then((response) => {
          this.isUploadingFiles = false
          this.uploadedImage = { filename: response.data }
          this.editedItem.imagePath = response.data
          this.newImageDialog = false
          this.clearFileInput()
        }).catch((e) => {
          this.isUploadingFiles = false
          this.clearFileInput()
          this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        })
      }
    },
    clearFileInput() {
      this.uploadedImage = null;
      this.isImageFormValid = false;
    },
    saveSendInvoiceDataUpdate(item) {
      const organization = item
      organization.sendInvoiceData = !organization.sendInvoiceData
      HTTP.post("/organization/save/sendinvoicedata", organization).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    },
    sendInvoiceBasisEmail() {
      this.$root.$ConfirmDialog
        .open("Send Invoice Data Email", "Are you sure?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post('/organization/send_invoice_data').catch(e => {
              this.$store.dispatch('showError', e.response.data)
            })
          }
        });
    },
    getitems() {
      HTTP.get('/organization/get').then(response => {
        this.tableItems = response.data
      }).catch(e => {
        if (e.response.status === 400) {
          this.$store.dispatch('showError', `Getting organizations failed: ${e.response.data} <br><br>Contact your admin if this issue persists.`)
        } else {
          this.$store.dispatch('showError', "Getting organizations failed: Could not get organizations. <br><br>Contact your admin if this issue persists.")
        }
      })
    },
    newitem() {
      this.clearFileInput()
      this.emailRequirements = [{ type: null, description: "", isEditable: true }]
      this.isSelectOwner = false
      this.isEditOwner = false
      this.selectedOrganizationOwner = null
      this.newItem = true
      this.editedItem = {
        id: 0,
        name: null,
        imagePath: null,
        isSuperAdminOrganization: false,
        languageOption: null,
        useLanguageOptions: false,
        sendInvoiceData: false,
        useForms: false,
        useEmailRequirement: false,
        emailRequirementRegex: null,
        emailRequirementHint: null,
        entraGroupId: null,
        useEntraIdSSO: false,
        ssoAuthMethod: 0,
        registrationUrl: null,
        organizationOwner: {
          id: 0,
          firstname: null,
          lastname: null,
          email: null,
          password: null
        }
      }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      this.idOfLastSelectedRow = item.id
      this.clearFileInput()
      this.emailRequirements = [{ type: null, description: "", isEditable: true }]
      this.isSelectOwner = false
      this.isEditOwner = false
      this.selectedOrganizationOwner = null
      var self = this
      self.editedItem = Object.assign({}, item)
      if (item.imagePath != null) {
        this.uploadedImage = { filename: item.imagePath }
      }
      if (item.emailRequirementRegex !== null) {
        this.emailRequirements = EmailRegexObjectBuilder(item.emailRequirementRegex)
      }
      self.dialog = true
      setTimeout(() => {
        if (this.$refs.organizationform) this.$refs.organizationform.resetValidation()
      }, 50)
    },
    save() {
      var self = this;
      if (self.isSelectOwner) {
        self.editedItem.organizationOwner = self.selectedOrganizationOwner
      }
      if (self.editedItem.useEmailRequirement) {
        self.editedItem.emailRequirementRegex = EmailRegexBuilder(this.emailRequirements)
      }

      HTTP.post('/organization/save', self.editedItem).then(async response => {
        self.isSelectOwner = false
        self.isEditOwner = false
        self.selectedOrganizationOwner = null
        this.organizationId = response.data.id
        if (this.uploadedImage != null) {
          await this.saveImage()
        }
        if (self.editedItem.id > 0) {

          var editedIndex = self.tableItems.findIndex(q => q.id === response.data.id);
          self.tableItems[editedIndex] = response.data;

          if (Number(this.organizationid) === response.data.id) {
            if (response.data.useForms) {
              this.$store.dispatch('doesOrganizationUseForms', true)
            } else {
              this.$store.dispatch('doesOrganizationUseForms', false)
            }
          }

          self.tableItems[editedIndex].imagePath = response.data.imagePath === null ? this.editedItem.imagePath : response.data.imagePath;
        } else {
          const newOrganization = response.data;
          newOrganization.imagePath = this.editedItem.imagePath
          self.tableItems.push(newOrganization)
          self.$store.dispatch('addOrganizationitem', newOrganization)
        }
        self.clearInputField()
        self.getOwners()
        self.dialog = false
      }).catch(e => {
        self.clearInputField()
        if (e.response.status === 400) {
          this.$store.dispatch('showError', `Saving organization failed: ${e.response.data} <br><br>Contact your admin if this issue persists.`)
        } else {
          this.$store.dispatch('showError', `Saving organization failed: ${this.editedItem.name} could not be saved. <br><br>Contact your admin if this issue persists.`)
        }
      })
    },
    deleteItem(item) {
      const self = this
      this.$root.$ConfirmDialog.open('Delete item', 'All related QR data including users will be deleted! Are you sure you want to continue?.', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/organization/delete', item).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1);
            self.$store.dispatch('resetSelectedOrganizationAndWorkspace');
            self.getOwners()
          }).catch(e => {
            if (e.response.status === 400) {
              this.$store.dispatch('showError', `Deleting organization failed: ${e.response.data} <br><br>Contact your admin if this issue persists.`)
            } else {
              this.$store.dispatch('showError', `Deleting organization failed: ${this.editedItem.name} could not be deleted. <br><br>Contact your admin if this issue persists.`)
            }
          })
        }
      })
    },
    clearInputField() {
      document.getElementById('image-dropbox-form').reset();
    },
    getUserFullName(item) {
      return `${item.firstname} ${item.lastname} (${item.email})`;
    },
  }
}
</script>
<style scoped>
.image-dialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dropbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;

  background-color: #EBF5FB;
  width: 100%;
  height: 100px;
  border-radius: 15px;
  border: 2px solid lightgrey;
  position: relative;
}

.input-file {
  width: 100%;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.upload-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
}

.file-count {
  font-weight: bold;
  font-style: italic;
}

::file-selector-button {
  display: none;
}
</style>
