<template>
  <div>
    <HeaderNoAccess></HeaderNoAccess>
    <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
      <v-card class="elevation-12" style=" width: 500px;">
        <v-card-title class="d-flex justify-center align-center" style="flex-direction: column;">
          <img v-if="logoImage" :src="`${backendurl}/shared/logo-images/${this.logoImage}`" style="max-height: 100px;">
          <span style="font-size: larger;">Register SSO account</span>
        </v-card-title>
        <v-card-text>
          <div class="pa-4">
            <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
              <v-btn @click="registerAccount()" block class="login-button">
                <div style="display: flex; align-items: center; gap: 8px;">
                  <img src="./../assets/Microsoft_icon.svg.png" style="max-height: 24px;" />
                  Microsoft
                </div>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';
import HeaderNoAccess from '../components/HeaderNoAccess.vue';
import { logout } from '../helpers/logoutHandler';

export default {
  components: {
    HeaderNoAccess
  },
  data() {
    return {
      logoImage: null,
      // eslint-disable-next-line no-undef
      backendurl: process.env.VUE_APP_APIURL,
    }
  },
  async created() {
  },
  async mounted() {
    await HTTP.get(`/organization/get/logo?entra-group-id=${this.$route.query["entra-group-id"]}`)
      .then((response) => {
        this.logoImage = response.data
      })
      .catch((e) => {
        console.log(e.response.data)
      })
    this.msalInstance.clearCache()
  },
  computed: {
    ...mapGetters(["msalInstance", "ssoAuthMethod"])
  },
  methods: {
    triggerLoginByEnter(e) {
      if (e.keyCode === 13) {
        this.registerAccount()
      }
    },
    async registerAccount() {
      this.loading = true
      delete HTTP.defaults.headers.common['Authorization']
      const ssoAuthMethod = this.$route.query["auth-method"]
      if (["SAML", "OIDC"].includes(ssoAuthMethod)) {
        await this.$store.dispatch("saveSSOAuthMethod", ssoAuthMethod)
        try {
          if (this.ssoAuthMethod === "SAML") {
            window.location.href = `https://localhost:44308/auth/register-account/saml?entra-group-id=${this.$route.query["entra-group-id"]}`
          }
          if (this.ssoAuthMethod === "OIDC") {
            const loginRequest = {
              scopes: ["User.Read"]
            };
            const msalResponse = await this.msalInstance.loginPopup(loginRequest);
            if (msalResponse.idTokenClaims.groups === null) {
              throw new Error("Account registration with OIDC failed: Your account has not yet been activated. <br><br>Contact your admin if this issue persists.")
            }

            await HTTP.post(`/auth/register-account/oidc?entra-group-id=${this.$route.query["entra-group-id"]}`, msalResponse.idTokenClaims.groups)
              .then((response) => {
                this.$store.dispatch('saveOrganizationId', response.data.organizationId);
                this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyId);
                this.$store.dispatch('saveWorkspaceId', response.data.workspaceIds.length > 0 ? response.data.workspaceIds[0] : -1);
                this.$store.dispatch('saveAuthorization', { username: response.data.email, isauthorized: true, bearertoken: HTTP.defaults.headers.common['Authorization'].substring("bearer ".length), isadministrator: response.data.isAdministrator, issuperadministrator: response.data.isSuperAdministrator });
                this.$router.push({ name: "SSOWelcome" })
                this.loading = false
              })
              .catch(async (e) => {
                if (e.response.status === 400) {
                  this.$router.push({ name: "AccountSignIn", query: { "error": `Account registration with OIDC failed: ${e.response.data} <br><br>Contact your admin if this issue persists.` } })
                }
                throw new Error(`Account registration with OIDC failed: Could not register account. <br><br>Contact your admin if this issue persists.`)
              })
          }
        } catch (error) {
          this.msalInstance.clearCache()
          this.$store.dispatch('showError', error)
          this.$store.dispatch('saveSSOAuthMethod', null);
          this.$store.dispatch('deleteAuthorization')
          this.authInfo = { email: '' }
          this.loading = false
        }
      }
      else {
        await logout()
      }
    },
  }
}

</script>

<style scoped lang="css">
.additional-login-info {
  margin-bottom: 25px;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  color: lightcoral;
}
</style>
