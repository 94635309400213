<template>
  <div class="adminbackground">
    <AutoLogout></AutoLogout>
    <HeaderMain></HeaderMain>
    <router-view></router-view>
  </div>
</template>

<script>
import AutoLogout from "../../components/AutoLogout.vue";
import { RouterView } from "vue-router";
import HeaderMain from "../../components/HeaderMain.vue";

export default {
  components: { HeaderMain, AutoLogout, RouterView },
};
</script>
