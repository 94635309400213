<template>
  <div>
    <HeaderNoAccess></HeaderNoAccess>
    <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
      <v-card class="elevation-12" style=" width: 500px;">
        <v-card-title class="d-flex justify-center">
          <span style="font-size: larger;">Change Password</span>
        </v-card-title>
        <v-card-text>
          <template v-if="!isChangePasswordSuccess">
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <v-form v-model="valid" ref="form">
                  <v-text-field :disabled="passwordsent" required v-model="password1" label="Password"
                    type="password"></v-text-field>
                  <v-text-field :disabled="passwordsent" required v-model="password2" label="Enter password again"
                    type="password"></v-text-field>
                </v-form>
              </div>
            </div>
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <v-btn @click="changePassword()" block class="login-button">
                  Continue
                </v-btn>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <span style="font-size: larger;">Password has been changed!</span>
              </div>
            </div>
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <v-btn @click="$router.push({ name: 'AccountSignIn', query: { 'email': this.email } })" block
                  class="login-button">
                  Back to Sign-in
                </v-btn>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import HeaderNoAccess from '../components/HeaderNoAccess.vue';

export default {
  props: ['guid'],
  data() {
    return {
      valid: true,
      loading: false,
      password1: null,
      password2: null,
      isChangePasswordSuccess: false
    }
  },
  created() {
  },
  components: {
    HeaderNoAccess
  },
  methods: {
    changePassword() {
      this.$store.dispatch('setLoading', true)
      if (this.$refs.form.validate() && this.password1 !== null && this.password1 === this.password2) {
        this.loading = true
        HTTP.post('/auth/change-password', { password: this.password1, guid: this.guid }).then(() => {
          this.$store.dispatch('setLoading', false)
          this.isChangePasswordSuccess = true
        }).catch(e => {
          this.$store.dispatch('setLoading', false)
          this.$store.dispatch('showError', e.response.data)
        })
      } else {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('showError', 'Enter the same password in both fields')
      }
    }
  }
}

</script>

<style lang="css" scoped></style>
