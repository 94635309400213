import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import store from "../store/index";
import { jwtDecode } from "jwt-decode";
import { logout } from "../helpers/logoutHandler";

export const HTTP = axios.create({
  // eslint-disable-next-line
  baseURL: process.env.VUE_APP_APIURL,
});

// Refresh token logic
async function refreshAccessToken() {
  const account = store.getters.msalInstance.getAllAccounts()[0];

  if (!account) {
    console.log("No signed-in account found!");
    return null;
  }

  const request = {
    account: account,
    scopes: [
      "api://ac5224de-b904-41c0-8e19-bd7f3abdd9b6/Data.Read",
      "api://ac5224de-b904-41c0-8e19-bd7f3abdd9b6/Data.ReadWrite",
    ],
  };
  try {
    // Try to get a token silently
    const response = await store.getters.msalInstance.acquireTokenSilent(request);
    return response.accessToken; // Return the access token
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // If silent acquisition fails, fall back to interactive methods
      try {
        const response = await store.getters.msalInstance.acquireTokenPopup(request);
        return response.accessToken; // Return the new token
      } catch (popupError) {
        console.error("Token acquisition via popup failed:", popupError);
        return null;
      }
    } else {
      console.error("Silent token acquisition failed:", error);
      return null;
    }
  }
}

HTTP.interceptors.request.use(
  async (config) => {
    if (store.getters.ssoAuthMethod !== "OIDC") {
      console.log("Skipping interceptor...");
      return config;
    }
    if (
      store.getters.bearertoken === "undefined" ||
      store.getters.bearertoken === null ||
      store.getters.bearertoken === undefined ||
      jwtDecode(store.getters.bearertoken).exp - 1000 < Math.floor(Date.now() / 1000)
    ) {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken === null) {
        console.log("Failed to refresh token, please login again");
        await logout();
      } else {
        HTTP.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default HTTP;
