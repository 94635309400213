<template>
  <div>
    <HeaderNoAccess></HeaderNoAccess>
    <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
      <v-card class="elevation-12" style=" width: 500px;">
        <v-card-title class="d-flex justify-center">
          <span style="font-size: larger;">Recover Password</span>
        </v-card-title>
        <v-card-text>
          <template v-if="!requestSent">
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <v-text-field v-model="email" label="Email*" v-on:keyup="checkreturn"></v-text-field>
              </div>
            </div>
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <v-btn @click="sendPasswordRecoveryRequest()" block class="login-button">
                  Continue
                </v-btn>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <span style="font-size: larger;">Password recovery request was successfully sent!</span>
              </div>
            </div>
            <div class="pa-4">
              <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
                <v-btn @click="$router.push({ name: 'AccountSignIn', query: { 'email': this.email } })" block
                  class="login-button">
                  Back to Sign-in
                </v-btn>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import HeaderNoAccess from '../components/HeaderNoAccess.vue';

export default {
  data() {
    return {
      email: this.$route.query["email"] ?? '',
      requestSent: false,
    }
  },
  components: {
    HeaderNoAccess
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    checkreturn(e) {
      if (e.keyCode === 13) {
        this.sendPasswordRecoveryRequest()
      }
    },
    sendPasswordRecoveryRequest() {
      this.$store.dispatch('setLoading', true)
      this.$router.replace({ name: 'RecoverPassword', query: { "email": this.email } })
      HTTP.post(`/auth/recover-password?email=${this.email}`).then(() => {
        this.$store.dispatch('setLoading', false)
        this.requestSent = true
      }).catch(e => {
        this.$store.dispatch('setLoading', false)
        if (e.response.status != 500) {
          this.$store.dispatch('showError', `Password recovery request failed: ${e.response.data} <br><br>Contact your admin if this issue persists.`)
        } else {
          this.$store.dispatch('showError', `Password recovery request failed <br><br>Contact your admin if this issue persists.`)
        }
      })
    }
  }
}

</script>

<style lang="css" scoped></style>
