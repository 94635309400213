<template>
  <v-app>
    <router-view></router-view>
    <v-dialog v-model="errordialog" persistent width="500">
      <v-card class="pa-4">
        <v-card-title class="headline">Message<v-icon large class="ml-3"
            color="red">mdi-alert-circle</v-icon></v-card-title>
        <v-card-text>
          <p v-html="errormessage"></p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn tile @click="clearerror()">Ok</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </v-app>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import ConfirmDialog from '@/components/ConfirmDialog'
import { logout } from './helpers/logoutHandler';

export default {
  name: 'App',

  components: { ConfirmDialog },
  data() {
    return {
    }
  },
  mounted() {
    this.$root.$ConfirmDialog = this.$refs.confirm
  },
  async created() {
    await this.$store.state.msalInstance.initialize()
    this.getUser()
    this.$store.dispatch('initStorage')
  },
  computed: {
    ...mapGetters([
      'errordialog',
      'errormessage',
      'isauthorized',
      'bearertoken',
      'workspaceid',
      'ssoAuthMethod'
    ])
  },
  methods: {
    getUser() {
      HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.bearertoken
      HTTP.get("/users/get/logged-in-user").then((response) => {
        this.$store.dispatch('saveAuthorization', {
          username: response.data.email,
          isauthorized: true,
          bearertoken: HTTP.defaults.headers.common['Authorization'].substring("bearer ".length),
          isadministrator: response.data.isAdministrator,
          issuperadministrator: response.data.isSuperAdministrator,
          isOwner: response.data.role === 1 ? true : false,
          hasWorkspaceAuthorization: response.data.hasWorkspaceAuthorization,
        });
        this.$store.dispatch('saveSSOAuthMethod', response.data.ssoAuthMethod);
      }).catch(async (e) => {
        console.log(e)
        if (this.isauthorized) {
          await logout()
        }
      })
    },
    clearerror() {
      this.$store.dispatch('clearError')
    },
  }
}
</script>
