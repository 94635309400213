<template>
  <div>
    <HeaderNoAccess></HeaderNoAccess>
  </div>
</template>

<script>
// import { HTTP } from '@/plugins/backendAPI'
import { logout } from '../helpers/logoutHandler';
import HTTP from '../plugins/backendAPI';
import HeaderNoAccess from '../components/HeaderNoAccess.vue';


export default {
  components: {
    HeaderNoAccess
  },
  data() {
    return {
      token: null,
      loading: false,
    }
  },
  async created() {
  },
  mounted() {
    this.$store.dispatch('setLoading', true);
    this.$store.dispatch('saveSSOAuthMethod', this.$route.query["auth-method"]);
    this.token = this.$route.query["token"]
    HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.token
    HTTP.get('/users/get/logged-in-user')
      .then(async (response) => {
        await this.$store.dispatch('saveAuthorization', {
          username: response.data.email,
          isauthorized: true,
          bearertoken: this.token,
          isadministrator: response.data.isAdministrator,
          issuperadministrator: response.data.isSuperAdministrator,
          hasWorkspaceAuthorization: response.data.hasWorkspaceAuthorization
        });
        await this.$store.dispatch('setLoading', false);
        this.$router.push({ name: "SSOWelcome" })
      })
      .catch(e => {
        this.$store.dispatch('showError', e.response.data)
        this.$store.dispatch('setLoading', false);
        logout()
      })
  },
}
</script>
