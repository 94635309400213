<template>
  <v-card>
    <div v-if="this.$route.name === 'SSOWelcome'" class="d-flex align-center px-10"
      style="min-height: 150px; justify-content: space-between;">
      <div class="d-flex align-center" style="min-height: 150px;">
        <RouterLink :to="{ name: 'AccountSignIn' }" class="d-flex" style="align-items: center;">
          <img src="./../assets/service-me-logo.png" style="max-height: 40px;">
        </RouterLink>
      </div>
      <div class="d-flex justify-end">
        <v-btn class="adminbutton" @click="triggerLogout"><v-icon class="mr-2">mdi-logout</v-icon>Log out</v-btn>
      </div>
    </div>
    <div v-else class="justify-between align-center px-10" style="display: grid; grid-template-columns: 20% 60% 20%">
      <div class="d-flex align-center" style="min-height: 150px;">
        <RouterLink :to="{ name: 'AccountSignIn' }" class="d-flex" style="align-items: center;">
          <img src="./../assets/service-me-logo.png" style="max-height: 40px;">
        </RouterLink>
      </div>
      <div class="d-flex justify-center pa-4">
        <div>
          <div class="justify-center"
            :class="isOwner || issuperadministrator ? 'organization-workspace-nav-bar' : 'workspace-nav-bar'">
            <v-select v-if="isOwner || issuperadministrator" :items="organizationitems" v-model="selectedOrganization"
              item-title="name" item-value="id" label="Choose organization" solo return-object
              style="margin-right: 12px;"></v-select>
            <v-select :items="workspaceitems" v-model="selectedWorkspace" item-title="name" item-value="id"
              return-object label="Choose workspace" solo style="margin-right: 12px;"></v-select>
          </div>
          <div class="justify-center" style="display: grid; grid-template-columns: 25% 25% 25% 25%">
            <v-btn to="/issues" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-check-circle</v-icon>
              </template>
              Issues
            </v-btn>
            <v-btn to="/qr" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-qrcode</v-icon>
              </template>
              Qr Codes
            </v-btn>
            <v-btn to="/inventory" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-pencil</v-icon>
              </template>
              Inventory
            </v-btn>
            <v-btn to="/statistics" style="margin-right: 12px;">
              <template v-slot:prepend>
                <v-icon>mdi-chart-line</v-icon>
              </template>
              Statistics
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-md-3 d-flex justify-end">
        <div class="d-flex flex-column">
          <v-btn class="adminbutton mb-8" @click="triggerLogout"><v-icon class="mr-2">mdi-logout</v-icon>Log out</v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn class="adminbutton" v-bind="props"><v-icon class="mr-2">mdi-cog</v-icon>Admin</v-btn>
            </template>
            <v-list>
              <v-list-item link v-for="(item, index) in menuitems" :key="index" router :to="item.url">
                <v-list-item-title class="menuitems"><v-icon>{{ item.icon }}</v-icon> {{ item.title
                  }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <v-progress-linear :active="loading" :indeterminate="loading" height="6" color="#572F7B"></v-progress-linear>
  </v-card>
</template>
<script>

import { HTTP } from '@/plugins/backendAPI';
import { mapGetters } from 'vuex';
import { logout } from '../helpers/logoutHandler';
// import { EventBus } from '@/store/event-bus.js';

export default {
  data() {
    return {
      menuitems: [],
      loading: false,
      selectedOrganization: null,
      selectedWorkspace: undefined,
    }
  },
  created() {
    this.getEverything()
  },
  computed: {
    ...mapGetters([
      'workspaceid',
      'useForms',
      'legacyworkspaceid',
      'workspaceitems',
      'organizationid',
      'organizationitems',
      'isadministrator',
      'issuperadministrator',
      'resetSelectedOrganizationAndWorkspace',
      'isOwner'
    ])
  },
  watch: {
    useForms() {
      this.menuitems = []
      this.fillmenuitems()
    },
    selectedWorkspace(newWorkspace) {
      if (newWorkspace === null) {
        this.$store.dispatch('saveWorkspaceId', -1)
      } else {
        this.$store.dispatch('saveWorkspaceId', newWorkspace.id)
      }
    },
    selectedOrganization(newOrganization) {
      this.$store.dispatch('saveOrganizationId', newOrganization.id)
      this.setSelectedOrganization(newOrganization.id)
    },
    resetSelectedOrganizationAndWorkspace() {
      this.getEverything()
    }
  },
  methods: {
    async triggerLogout() {
      await logout()
    },
    goToHome() {
      this.$router.push('/')
    },
    async getEverything() {
      this.getorganizations();
      this.getworkspaces();
    },
    fillmenuitems() {
      if (this.issuperadministrator) {
        this.menuitems.push({ title: 'Organizations', icon: 'mdi-domain', url: '/organization' })
        this.menuitems.push({ title: 'System Inventory Types', icon: 'mdi-dresser-outline', url: '/inventorytype/system' })
      }
      if (this.isadministrator || this.issuperadministrator) {
        this.menuitems.push({ title: 'Organization Users', icon: 'mdi-account', url: '/users' })
        this.menuitems.push({ title: 'Workspaces', icon: 'mdi-sitemap', url: '/workspace' })
      }
      this.menuitems.push({ title: 'QR categories', icon: 'mdi-devices', url: '/qrcategory' })
      this.menuitems.push({ title: 'Locations', icon: 'mdi-home-map-marker', url: '/location' })
      if (this.useForms) {
        this.menuitems.push({ title: 'Forms', icon: 'mdi-format-list-numbered', url: '/form' })
      }
    },
    async getorganizations() {
      this.$store.dispatch('clearOrganizationitems')
      if (this.organizationitems.length < 1) {
        await HTTP.get('/organization/get/simple')
          .then((response) => {
            if (response.data.length > 0) {
              response.data.forEach(element => {
                this.$store.dispatch('addOrganizationitem', element)
              });
              const localStorageOrganizationIdExists = response.data.find(w => w.id === Number(this.organizationid))
              if (!localStorageOrganizationIdExists) {
                this.selectedOrganization = response.data[0]
              } else {
                this.selectedOrganization = localStorageOrganizationIdExists
              }
            }
            this.menuitems = []
            this.fillmenuitems()
          }).catch(() => {
          })
      }
    },
    async getworkspaces() {
      this.$store.dispatch('clearWorkspaceitems')
      if (this.workspaceitems.length < 1 && this.organizationid > 0) {
        await HTTP.get('/workspace/getlist/' + this.organizationid).then(response => {
          this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyWorkspaceId)
          if (response.data.workspaces.length === 0) {
            this.selectedWorkspace = null
          }
          else {
            response.data.workspaces.forEach(element => {
              this.$store.dispatch('addWorkspaceitem', element)
            });
            const localStorageWorkspaceIdExists = response.data.workspaces.find(w => w.id == this.workspaceid)
            if (!localStorageWorkspaceIdExists) {
              this.selectedWorkspace = response.data.workspaces[0]
            } else {
              this.selectedWorkspace = localStorageWorkspaceIdExists
            }
          }
        }).catch(() => {
        })
      }
    },
    // eslint-disable-next-line no-unused-vars
    setSelectedOrganization(organizationid) {
      HTTP.get(`/organization/form/c/${organizationid}`).then((response) => {
        this.$store.dispatch('doesOrganizationUseForms', response.data)
        if (!response.data && this.$route.fullPath.split("/")[1] === "form") {
          this.$store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
          this.$router.push({ name: "Issues" })
        }
        this.getworkspaces();
      }).catch(() => {
        this.$store.dispatch('doesOrganizationUseForms', false)
        if (this.$route.fullPath.split("/")[1] === "form") {
          this.$store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
          this.$router.push({ name: "Issues" })
        }
      })
    }
  }
}
</script>
<style lang="css" scoped></style>
