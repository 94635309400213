<template>
  <div>
    <HeaderNoAccess></HeaderNoAccess>
    <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
      <v-card class="elevation-12" style=" width: 500px;">
        <v-card-title class="d-flex justify-center">
          <span style="font-size: larger;">{{ !authSelection ? "Welcome Back!" : "Enter Password" }}</span>
        </v-card-title>
        <v-card-text>
          <div class="pa-4">
            <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
              <div class="d-flex align-center" style="gap: 12px; position: relative;">
                <v-text-field :disabled="authSelection" v-model="email" label="Email*"
                  :name="authSelection ? 'not-email' : 'email'" type="text" ref="email_input_field"
                  class="text-field-disabled-opacity-override" hide-details
                  v-on:keyup="triggerFindAuthMethodByEnter"></v-text-field>
                <v-icon v-if="authSelection" @click.stop="triggerEmailEditable()" color="#572F7B" size="32px"
                  class="pa-5 data-table-button"
                  style="position: absolute; left: calc(100% - 40px - 12px);">mdi-pencil</v-icon>
              </div>
              <v-text-field v-if="authSelection && email.length >= 1" v-model="password" id="password" label="Password*"
                name="password" type="password" hide-details ref="password_input_field"
                v-on:keyup="triggerLoginByEnter"></v-text-field>
            </div>
          </div>
          <div class="px-4">
            <RouterLink v-if="authSelection" :to="{ name: 'RecoverPassword', query: { 'email': this.email } }"
              class="pl-4 router-link">
              Forgot password?
            </RouterLink>
          </div>
          <div class="pa-4">
            <div class="d-flex" style="flex-direction: column; justify-content: space-between; gap: 12px;">
              <v-btn v-if="!authSelection" @click="triggerauthSelection()" block class="login-button">
                continue
              </v-btn>
              <v-btn v-if="authSelection && email.length >= 1" @click="login()" block class="login-button">
                continue
              </v-btn>
              <div v-if="authSelection && email.length >= 1" style="display: grid; grid-template-columns: 30% 40% 30%;">
                <div class="d-flex" style="align-items: center; color: whitesmoke;">
                  <hr style="width: 100%;">
                </div>
                <p style="text-align: center; color: gray;">OR SIGN IN USING</p>
                <div class="d-flex" style="align-items: center; color: red;">
                  <hr style="width: 100%;">
                </div>
              </div>
              <v-btn v-if="authSelection && email.length >= 1" @click="ssoLogin()" block class="login-button">
                <div style="display: flex; align-items: center; gap: 8px;">
                  <img src="./../assets/Microsoft_icon.svg.png" style="max-height: 24px;" />
                  Microsoft
                </div>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';
import HeaderNoAccess from '../components/HeaderNoAccess.vue';

export default {
  beforeRouteLeave(to) {
    // console.log("Leaving login route...");
    // console.log(from)
    // console.log(to.fullPath)
    // console.log(this.$store.getters.redirectRoute)
    // console.log(to.fullPath !== this.$store.getters.redirectRoute)
    if (this.$store.getters.redirectRoute !== null && to.fullPath !== this.$store.getters.redirectRoute) {
      this.$router.push({
        path: this.$store.getters.redirectRoute,
      });
    } else {
      this.$store.dispatch("setRedirectRoute", null)
    }
  },
  components: {
    HeaderNoAccess
  },
  data() {
    return {
      email: this.$route.query["email"] ?? '',
      password: '',
      authSelection: this.$route.query["auth-selection"] === "true" ? true : false,
      prevRoute: null,
    }
  },
  async created() {
    if (this.$route.query["error"]) {
      this.$store.dispatch('showError', this.$route.query["error"])
      this.$router.replace({ name: 'AccountSignIn' })
    }
  },
  mounted() {
    this.msalInstance.clearCache()
  },
  computed: {
    ...mapGetters(["msalInstance", "ssoAuthMethod"])
  },
  methods: {
    triggerFindAuthMethodByEnter(e) {
      if (e.keyCode === 13) {
        this.triggerauthSelection()
      }
    },
    triggerLoginByEnter(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    triggerauthSelection() {
      if (this.email) {
        this.authSelection = true
        this.$router.replace({ name: 'AccountSignIn', query: { 'email': this.email, 'auth-selection': true } })
        setTimeout(() => {
          this.$refs.password_input_field.focus()
        }, 200);
        return
      }
      this.$store.dispatch('showError', 'Wrong email or password!')
    },
    triggerEmailEditable() {
      this.password = ""
      this.$router.replace({ name: 'AccountSignIn', query: { 'email': this.email } })
      this.authSelection = false
      setTimeout(() => {
        this.$refs.email_input_field.focus()
      }, 200);
    },
    async findAuthMethod() {
      await this.$store.dispatch('saveSSOAuthMethod', null);
      await HTTP.get("/auth/get/method", { params: { email: this.email } }).then(async (response) => {
        await this.$store.dispatch('saveSSOAuthMethod', response.data.ssoAuthMethod);
      }).catch(() => {
        throw new Error(`Sign-in request failed.`)
      })
    },
    async ssoLogin() {
      try {
        await this.findAuthMethod()
        if (this.ssoAuthMethod === "SAML") {
          this.$store.dispatch("setLoading", true)
          await this.$store.dispatch("saveSSOAuthMethod", "SAML")
          delete HTTP.defaults.headers.common['Authorization']
          window.location.href = `https://localhost:44308/auth/sign-in/saml?email=${this.email}`
        } else if (this.ssoAuthMethod === "OIDC") {
          this.$store.dispatch("setLoading", true)
          await this.$store.dispatch("saveSSOAuthMethod", "OIDC")
          delete HTTP.defaults.headers.common['Authorization']
          const loginRequest = {
            scopes: ["User.Read"],
            loginHint: this.email
          };
          const msalResponse = await this.msalInstance.loginPopup(loginRequest);
          if (msalResponse.idTokenClaims.groups === null) {
            throw new Error("Sign-in request failed: Your account has not yet been activated. <br><br>Contact your admin if this issue persists.")
          }
          await HTTP.post('/auth/sign-in/oidc', msalResponse.idTokenClaims.groups)
            .then((response) => {
              this.$store.dispatch('saveOrganizationId', response.data.organizationId);
              this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyId);
              this.$store.dispatch('saveWorkspaceId', response.data.workspaceIds.length > 0 ? response.data.workspaceIds[0] : -1);
              this.$store.dispatch('saveAuthorization', { username: response.data.email, isauthorized: true, bearertoken: HTTP.defaults.headers.common['Authorization'].substring("bearer ".length), isadministrator: response.data.isAdministrator, issuperadministrator: response.data.isSuperAdministrator });
              this.$router.push({ name: "Issues" })
              this.$store.dispatch("setLoading", false)
            })
            .catch(async (e) => {
              if (e.response.status === 400) {
                throw new Error(`Sign-in request failed: ${e.response.data} <br><br>Contact your admin if this issue persists.`)
              }
              throw new Error(`Sign-in request failed: Could not sign in user. <br><br>Contact your admin if this issue persists.`)
            })
        }
      } catch (error) {
        this.$router.replace({ name: 'AccountSignIn' })
        this.msalInstance.clearCache()
        this.$store.dispatch('showError', error)
        this.$store.dispatch('saveSSOAuthMethod', null)
        this.$store.dispatch('deleteAuthorization')
        this.authSelection = false
        this.email = ''
        this.password = ''
      }
      this.$store.dispatch("setLoading", false)
    },
    async login() {
      this.$store.dispatch("setLoading", true)
      await this.$store.dispatch('saveSSOAuthMethod', 'NONE');
      delete HTTP.defaults.headers.common['Authorization']
      HTTP.post("/auth/sign-in/jwt", { email: this.email, password: encodeURIComponent(this.password) }).then((response) => {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.bearer
        if (response.data.user.role != 1) {
          this.$store.dispatch('saveOrganizationId', response.data.user.organizationId);
        } else {
          this.$store.dispatch('saveOrganizationId', response.data.user.ownerOrganizations[0].id);
        }
        this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyId);
        this.$store.dispatch('saveWorkspaceId', response.data.user.workspaceId);
        this.$store.dispatch('saveAuthorization', { username: this.email, isauthorized: true, bearertoken: response.data.bearer, isadministrator: response.data.user.isAdministrator, issuperadministrator: response.data.user.isSuperAdministrator });
        this.$store.dispatch("setLoading", false)
        this.$router.push({ name: "Issues" })
      }).catch((e) => {
        if (e.response.status == 400) {
          this.$store.dispatch('showError', `Sign-in request failed: ${e.response.data} <br><br>Contact your admin if this issue persists.`)
        } else {
          this.$store.dispatch('showError', 'Sign-in request failed: Wrong email or password! <br><br>Contact your admin if this issue persists.')
        }
        this.$router.replace({ name: 'AccountSignIn' })
        this.$store.dispatch('saveSSOAuthMethod', null);
        this.$store.dispatch('deleteAuthorization')
        this.email = ''
        this.password = ''
        this.authSelection = false
        this.$store.dispatch("setLoading", false)
      })
    },
    recoverpassword() {
      this.$router.push({ path: '/recoverpassword' })
    }
  }
}
</script>

<style scoped lang="css">
.additional-login-info {
  margin-bottom: 25px;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  color: lightcoral;
}
</style>
