<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div style="display: flex; justify-content: center;">
      <div class="align-start" style="padding: 24px;">
        <h2 class="mr-auto">Thank you for logging in!</h2>
        <p>
          A notification has been sent to your system administrator, with a request of access. <br><br>
          He will notify you when access has been granted.
        </p>
        <p>
          Best regards,<br><br>
          Team Service-Me
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
// import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
  },
}
</script>
