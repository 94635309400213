<template>
  <div style="padding: 0 32px; width: 100%;">
    <v-progress-linear :active="loading" indeterminate color="blue"></v-progress-linear>
    <div class="mobile-view">
      <div class="infotext text-center mb-5">
        <span v-if="languageOption === 'SWEDISH'"> {{ isEditing ? "Redigera" : "Registrera en" }} Qr-kod genom att fylla
          i formuläret nedan</span>
        <span v-else>{{ isEditing ? "Edit" : "Register a" }} QR-code by filling out the form below.</span>
      </div>
      <v-form v-model="isQrFormValid">
        <v-select required :rules="requiredRules" :items="qrCategories" item-title="description" item-value="id"
          return-object :label="languageOption === 'SWEDISH' ? 'Välj typ' : 'Select type'"
          v-model="qrCodeItem.qrCategory" solo></v-select>
        <div v-if="qrCodeItem.qrCategory !== null">
          <v-text-field required :rules="requiredRules"
            :label="languageOption === 'SWEDISH' ? '*Beskrivning' : '* Description'" v-model="qrCodeItem.description"
            outlined></v-text-field>
          <div style="display: flex; width: 100%;align-items: center; margin-bottom: 22px;">
            <div style=" width: 100%;">
              <v-combobox v-model="qrCodeItem.location" :items="locations" item-value="id" item-title="name"
                hide-details :label="languageOption === 'SWEDISH' ? 'Plats' : 'Location'" outlined></v-combobox>
            </div>
            <div v-if="qrCodeItem.location && qrCodeItem.location.imageId" style="align-items: center; margin: 0 12px">
              <v-icon size="30px" :class="qrCodeItem.latLng && 'map-marker-selected'" @click="mapDialog = true"
                style="color: var(--report-red); margin: auto 0; position: relative;">
                mdi-map-marker-radius
              </v-icon>
            </div>
          </div>
          <v-text-field :label="languageOption === 'SWEDISH' ? 'Plats beskrivning' : 'Location description'"
            v-model="qrCodeItem.position" outlined></v-text-field>
          <div v-if="useForms" style="margin-bottom: 22px;">
            <div class="switch-table-row">
              <p style="margin: auto 0; height: fit-content;">{{ getForms[0].description }}</p>
              <v-switch color="green" @update:model-value="switchContactRequestActive" v-model="getForms[0].isActive"
                class="switch-toggle" hide-details inset></v-switch>
            </div>
            <div class="switch-table-row">
              <p style="margin: auto 0; height: fit-content;">{{ getForms[1].description }}</p>
              <v-switch color="green" @update:model-value="switchFeedbackActive" v-model="getForms[1].isActive"
                class="switch-toggle" hide-details inset></v-switch>
            </div>
          </div>
          <div v-for="(item, index) in qrCodeItem.qrDetails" :key="index" style="display: flex;">
            <div class="newitem pa-3" style="margin-bottom: 22px; width: 100%">
              <div style="margin-bottom: 22px; display: flex; justify-content: space-between;">
                <p class="infotext" style="margin: auto 0; max-width: fit-content;">
                  <span v-if="languageOption === 'SWEDISH'">Inventarie</span>
                  <span v-else>Inventory</span>
                </p>
                <v-btn @click="removeItem(index)" size="36px">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
              <div style="display: flex;">
                <v-select required :rules="requiredRules"
                  :items="item == null ? inventoryItems.filter(ii => ii.isActive) : inventoryItems.filter(ii => ii.isActive || ii.id == item.inventoryId)"
                  item-title="description" item-value="id" :label="languageOption === 'SWEDISH' ? '*Artikel' : '* Item'"
                  v-model="item.inventoryId"></v-select>
              </div>
              <div>
                <v-text-field
                  :label="languageOption === 'SWEDISH' ? 'Beskrivning av vald Artikel' : 'Custom Type Description'"
                  v-model="item.aliasTypeDescription" hide-details></v-text-field>
              </div>
            </div>
          </div>
          <div style="padding-bottom: 22px;">
            <v-btn @click="addItem()" class="addbutton" dark color="grey">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-form>
      <div style="display: flex; justify-content: center;">
        <template v-if="isEditing">
          <v-btn v-if="qrstring === undefined" class="smallbutton mb-7 me-5"
            @click="$router.push({ name: 'MobileSelect', params: { workspaceId: $router.currentRoute.value.params.workspaceId, qrWorkspaceId: $router.currentRoute.value.params.qrWorkspaceId } })"
            :loading="sending" :disabled="sending">
            <span v-if="languageOption === 'SWEDISH'"> {{ isEditing ? 'Tillbaka' : 'Återställ' }}</span>
            <span v-else>{{ isEditing ? 'Back' : 'Reset' }}</span>
          </v-btn>
          <v-btn v-else class="smallbutton mb-7 me-5"
            @click="$router.push({ name: 'MobileSelectLegacy', params: { qrString: $router.currentRoute.value.params.qrstring } })"
            :loading="sending" :disabled="sending">
            <span v-if="languageOption === 'SWEDISH'"> {{ isEditing ? 'Tillbaka' : 'Återställ' }}</span>
            <span v-else>{{ isEditing ? 'Back' : 'Reset' }}</span>
          </v-btn>
        </template>
        <v-btn class="smallbutton mb-7" @click="save()" :loading="sending" :disabled="!isQrFormValid">
          <span v-if="languageOption === 'SWEDISH'">Spara</span>
          <span v-else>Save</span>
        </v-btn>
      </div>
    </div>
    <v-dialog fullscreen v-model="mapDialog">
      <div class="map-area-mobile">
        <div v-if="isEditing" class="map-area-mobile">
          <MapComponent v-if="qrCodeItem.location != null && qrCodeItem.location.imageName && mapDialog" :qrId="-1"
            :name="qrCodeItem.location.imageName" :mobileEdit="true"
            :qrs="[{ qrId: qrCodeItem.id, qrString: qrCodeItem.id, qrDescription: qrCodeItem.description, latLng: qrCodeItem.latLng }]"
            :marker-draggable="true" @updateLocation="updateLocationFromChild" />
        </div>
        <div v-else class="map-area-mobile">
          <MapComponent v-if="qrCodeItem.location != null && qrCodeItem.location.imageName && mapDialog" :qrId="-1"
            :name="qrCodeItem.location.imageName" :mobileEdit="true" @updateLocation="updateLocationFromChild" />
        </div>
        <div class="mobile-map-buttons">
          <v-btn tile outlined class="mb-3 mr-3 mobile-map-button" @click="mapDialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import MapComponent from '../components/MapComponent.vue'
import { logout } from '../helpers/logoutHandler'



export default {
  props: [
    'qrname',
    'qrstring',
    'workspaceid',
    'qrworkspaceid',
    'isEditMode'
  ],
  components: {
    MapComponent,
  },
  computed: {
    ...mapGetters([
      'username',
      'useForms'
    ]),
    contactRequest() {
      return this.languageOption === 'SWEDISH' ? 'Kontakt formulär' : 'Contact request form';
    },
    feedback() {
      return this.languageOption === 'SWEDISH' ? 'Feedback formulär' : 'Feedback form';
    },
    languageOption() {
      return this.$store.state.languageOption;
    },
    getForms() {
      return [
        { description: this.contactRequest, isActive: this.qrCodeItem.isContactRequestActive, },
        { description: this.feedback, isActive: this.qrCodeItem.isFeedbackActive },
      ]
    }
  },
  data() {
    return {
      isChangeCategoryConfirmed: false,
      isCategoryReverting: false,
      qrCodeDescriptionDisplayValue: "",
      qrCodeItem: {
        id: 0,
        workspaceId: this.workspaceid,
        qrWorkspaceId: this.qrworkspaceid,
        isLegacyQr: false,
        qrCategory: null,
        location: null,
        isContactRequestActive: false,
        isFeedbackActive: false,
        qrDetails: [],
      },
      isNewLegacyQRCode: false,
      workspaceId: 0,
      isEditing: null,
      qrCategories: [],
      newQrCategoryId: null,
      localpicture: {},
      saving: false,
      loading: false,
      sending: false,
      isQrFormValid: false,
      inventoryItems: [],
      show: true,
      header: this.isEditMode ? "Edit QR" : "Register QR",

      formHeaders: [
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Active', value: 'isActive', sortable: false, width: 150 },
      ],
      requiredRules: [
        v => !!v || 'Field is required',
      ],

      locations: [],

      editQrId: null,
      mapDialog: false,

      isAuthorized: null,
      checkDone: false
    }
  },
  created() {
    this.getQrData()
  },
  watch: {
    "qrCodeItem.qrCategory": function (newValue, oldValue) {
      const isUncategorized = this.qrCodeItem.qrCategory.description === ' Uncategorized'
      if (oldValue === undefined || oldValue === null) {
        this.getInventoryByType(newValue.id)
        return
      }
      if (oldValue.id === newValue.id || this.isCategoryReverting) {
        this.isCategoryReverting = false
        return
      }
      if (this.qrCodeItem.qrDetails.length > 0 && !isUncategorized) {
        if (this.languageOption === 'SWEDISH') {
          return this.$root.$ConfirmDialog.open('Ändra QR Kategori', 'Är du säker? Alla valda artiklar kommer bli borttagna')
        } else if (this.languageOption === 'ENGLISH') {

          this.$root.$ConfirmDialog.open('Change QR Category', 'Are you sure? All current items will be removed.').then((response) => {
            if (response) {
              if (newValue.description !== ' Uncategorized') this.qrCodeItem.qrDetails = []
              this.getInventoryByType(newValue.id)
              return
            } else {
              this.isCategoryReverting = true
              this.qrCodeItem.qrCategory = oldValue
              return
            }
          });
        }
      } else {
        this.getInventoryByType(newValue.id)
        return
      }
      return
    }
  },
  methods: {
    async triggerLogout() {
      await logout()
    },
    addItem() {
      this.qrCodeItem.qrDetails.push({ id: -1 });
    },
    removeItem(index) {
      this.qrCodeItem.qrDetails.splice(index, 1)
    },
    getInventoryByType(qrCategoryId) {
      const isUncategorized = this.qrCodeItem.qrCategory.description === ' Uncategorized'
      HTTP.get(`/qr/getinventory/${qrCategoryId}/${isUncategorized}/${this.workspaceId}`).then((response) => {
        this.inventoryItems = response.data
      }).catch(() => { })
    },
    save() {
      if (typeof this.qrCodeItem.location == "string") {
        const newLocation = { name: this.qrCodeItem.location, workspaceId: this.workspaceId, usePinCode: false }
        this.qrCodeItem.location = newLocation
      }
      HTTP.post('/qr/save/mobile_edit',
        this.qrCodeItem).then(async () => {
          await HTTP.get(
            `/mobil/checkifqrexists/${this.qrstring}/${this.workspaceid}/${this.qrworkspaceid}`
          )
            .then(async (response) => {
              await this.$store.dispatch("saveQrCodeStatus", response.data.isFound);
              await this.$store.dispatch("doesOrganizationUseForms", response.data.useForms);
              if (response.data.isUsePinCode) {
                await this.$store.dispatch("usePinCodeForMobileAuth");
              } else {
                await this.$store.dispatch("dontUsePinCodeForMobileAuth");
              }
            })
            .catch((err) => {
              console.error(err)
            });
          if (this.$route.params.qrstring != undefined) {
            this.$router.push({ name: "MobileLegacyEditSuccess", params: { qrstring: this.$route.params.qrstring }, query: { operation: this.qrCodeItem.id === 0 ? "created" : "edited" } })
          }
          if (this.$route.params.workspaceid != undefined && this.$route.params.qrworkspaceid != undefined) {
            this.$router.push({ name: "MobileEditSuccess", params: { workspaceid: this.$route.params.workspaceid, qrworkspaceid: this.$route.params.qrworkspaceid }, query: { operation: this.qrCodeItem.id === 0 ? "created" : "edited" } })
          }
        }).catch((err) => {
          console.error(err)
          this.$store.dispatch("showError", `Error when saving: Qr-code "${this.qrCodeItem.description}" could not be saved.`);
        })
    },
    switchContactRequestActive(item) {
      this.qrCodeItem.isContactRequestActive = item;
    },
    switchFeedbackActive(item) {
      this.qrCodeItem.isFeedbackActive = item;
    },
    async getQrData() {
      this.loading = true
      let getSingleQRCodeEndpointVariable;

      if (this.qrstring !== undefined) {
        getSingleQRCodeEndpointVariable = this.qrstring
      } else {
        getSingleQRCodeEndpointVariable = `${this.workspaceid}/${this.qrworkspaceid}`
      }
      await HTTP.get('/qr/get/s/' + getSingleQRCodeEndpointVariable).then(response => {
        this.loading = false
        this.qrCodeDescriptionDisplayValue = response.data.description
        this.workspaceId = response.data.workspaceId
        this.qrCodeItem = response.data
        if (response.data.id === 0) {
          this.isEditing = false
        } else {
          this.isEditing = true
        }
        this.getComplementoryData()
      }).catch(async (error) => {
        this.loading = false
        if (error.response.status === 401) {
          await this.$store.dispatch('showError', 'Error when fetching qr info: ' + error.response.data)
          await this.triggerLogout()
        } else if (error.response.status === 400) {
          await this.$store.dispatch('showError', 'Error when fetching qr info: ' + error.response.data)
          await this.triggerLogout()
        }
      })
    },
    async getComplementoryData() {
      await HTTP.get(`/location/get/${this.workspaceId}`).then(response => {
        this.locations = response.data
      }).catch(() => { })
      await HTTP.get(`/mobil/organization/form/c/workspace/${this.workspaceId}`).then((response) => {
        this.$store.dispatch('doesOrganizationUseForms', response.data)
      }).catch(() => {
        this.$store.dispatch('doesOrganizationUseForms', false)
      })
      await HTTP.get('/qr/qr_category/get/a/workspace/' + this.workspaceId).then((response) => {
        this.qrCategories = response.data
      }).catch(() => { })
    },
    updateLocationFromChild(value) {
      this.qrCodeItem.latLng = value
    },
    clearCoordinates() {
      this.qrCodeItem.latLng = null
    },
  }
}
</script>

<style scoped>
.remove-btn {
  display: block !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  border-radius: 50%;
  cursor: pointer;
}

.switch-table-row {
  display: flex;
  justify-content: space-between;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #3D3D3D;
}

.switch-toggle {
  margin: 0 !important;
  padding: 0 !important;
}

.mobile-view {
  max-width: 600px;
  min-width: 270px;
  width: 100%;
  margin: auto auto;
}

.mobile-map-buttons {
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 0;
}

.mobile-map-button {
  background-color: #fff;
  border: 2px solid #ccc;
  width: 90px;
}
</style>
